
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer cadastro da rota do sistema e configurar o icone de acesso. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {useRoute, useRouter} from "vue-router";
import {computed,reactive,onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from 'axios';

export default {
  name: "FuncaoCreate",
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      short_name: '',
      long_name: '',
      linkname: '',
      icon:'',
      route: '',
      func_pai: 0,
      note: '',
      user_action: 1
    });

    const submit = async () => {
      await axios.post('funcoes', data);

      if (route.params.t=== 'f')
          await router.push(`/funcoes/${route.params.t}/${route.params.id}/${0}`);
      else if (route.params.t=== 'p'){
        await router.push(`/funcoes/${route.params.t}/${route.params.id}/${route.params.perfil}`);
      }

      // voltar();

      //await router.push('/funcoes');
    }

    const voltar = async () => {
        if (route.params.t=== 'f')
            await router.push(`/funcoes/${route.params.t}/${route.params.id}/${0}`);
        else if (route.params.t=== 'p'){
          await router.push(`/funcoes/${route.params.t}/${route.params.id}/${route.params.perfil}`);
        }

     }


    return {
      data,
      submit,
      voltar
    }
  }
}
