<template>

 <div class="home" > 

    <div class="vld-parent">
        <loading :active ="isLoading"
                :can-cancel="true"
                :on-cancel="onCancel"
                :is-full-page="true" />
    </div>
      

        <div class="container mt-2">
            <div class="row">
              <div>
                <div> Seja bem vindo, <b> {{ user.first_name}} {{user.last_name}} </b></div>
              </div>
            </div>

        </div>


            <div class="container col-xxl-12 px-4 py-1">
                  <div class="row flex-lg-row-reverse align-items-center ">
                    
                    <div class="col-md-6">
                            <div class="row ">
                                <div class="col-md-6" v-for="pcard in postCardFixo" :key="pcard.id">
                                    <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                      <div class="col-auto d-none d-lg-block">
                                        <img :src="`${serverimg}${pcard.image}`" width="300" height="180" :title="pcard.short_name"/>                             
                                      </div>
                                      <div class="col p-4 d-flex flex-column position-static">
                                        
                                        <h5 class="mb-0">{{pcard.short_name}} </h5>
                                        
                                        <!-- <p class="card-text mb-auto">{{ pcard.long_name }}
                                        </p> -->
                                        <a :href="`${pcard.url}`" target="_blank" class="stretched-link">Saiba mais</a>
                                      </div>
                                    </div>
                                </div>                      
                            </div>
                    </div>

                    <div class="col-md-6">
                        <!-- <Comunicado />   -->
                    </div>

                  </div>
        </div>

      
      <div class="b-example-divider" v-show="showMeuslinks"></div>

      <!-- <div class="container px-4 py-5" id="hanging-icons" v-show="showMeuslinks">
           <h4 class="pb-3 "><font-awesome-icon icon="anchor" />  Meus Links</h4>
              <LinksCard :plinks="filteredLinks" :filters="filters" @set-filters="filtersChanged" :lastPage="lastPage"/>
      </div>

      <div class="b-example-divider"></div>

      <div class="container px-4 py-5" id="hanging-icons" >
           <h4 class="pb-3 "><font-awesome-icon icon="external-link-alt" />  Links Externos</h4> 
              <LinksCard :plinks="filteredLinksPubCard" :filters="filterspub" @set-filters="filtersPubChanged" :lastPage="lastPagePub"/>
     </div> -->


     <div class="b-example-divider" v-show="totalcard > 0"></div>

      <div class="container px-4 py-5" id="hanging-icons" v-show="totalcard > 0" >
           <h4 class="pb-3 "><font-awesome-icon icon="external-link-alt" />  Destaques</h4> 
           
                    <div class="row ">

                      <div class="col-md-3" v-for="pcard in postCard" :key="pcard.id">
                        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                          <div class="col-auto d-none d-lg-block">
                             <img :src="`${serverimg}${pcard.image}`" width="300" height="180" :title="pcard.short_name"/>    

                          </div>
                          <div class="col p-4 d-flex flex-column position-static">
                            <!-- <strong class="d-inline-block mb-2 text-success">Design</strong> -->
                            <h5 class="mb-0">{{pcard.short_name}}</h5>
                            <!-- <div class="mb-1 text-muted">Nov 11</div> -->
                            <!-- <p class="mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p> -->
                            <a :href="`${pcard.url}`" target="_blank" class="stretched-link">Saiba mais</a>
                          </div>

                        </div>
                      </div>
                                          


                    </div>


     </div>


  </div> 

</template>

<script >
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: É a pagina home do sistema quando o usuário está logado. O link individual do usúario só vai ser mostrado se 
          tiver algum vinculado a ele, é claro.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:Patrick Duarte
 * Ref Autor: https://patrickduarte.github.io
 * RDM: 2022000049
 * Data: 15/06/2022
 * Objetivo: Alterar função comunicado para abrir pagina web ao clicar no slide. E também incluir componente card e card fixo no top, para ser uma alternativa de mostrar
 *           comunicados. Eles ficarão integrado com o blog da intranet.
 */
import LinksCard from "@/views/Links/LinksCard.vue";
import {computed,onMounted, reactive, ref} from "vue";

import axios from "axios";
import { Filter } from '@/models/filter';

import {useStore} from "vuex";
import {useRoute,useRouter} from "vue-router";
import Comunicado from '@/components/Comunicado.vue';

import Link from '@/components/Link.vue';

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import 'vue3-carousel/dist/carousel.css';

export default {
    name:'AreaLink',
      components: {
        LinksCard,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Comunicado,
        Link,
        Loading
    },
    setup() {
      const allLinks = ref([]);
      const filteredLinks = ref([]);
      const filters = reactive({
        s:'',
        sort:'',
        page:1
      });

      const filterspub = reactive({
        s:'',
        sort:'',
        page:1
      });

      const router = useRouter();


      const lastPage = ref(0);
      const perPage = 12;

      const store = useStore();
      const route = useRoute();        
      const user = computed( () => store.state.user);
      const isLoading = ref(false);   

      const slides = ref([]);
      const total = ref(0);
      const serverimg = ref('');
      const boasvindas = ref('');


      const filteredLinksPubCard = ref([]);
      const cat_pub = ref(0);
      const categoriaLinks = ref([]);
      const links = ref([]);
      const lastPagePub = ref(0);
      const showMeuslinks = ref(false);

      const totalcard = ref(0);

      const postCardFixo= ref([]);

      const postCard= ref([]);

      onMounted( async () => {

        isLoading.value = true;
        const resp = await axios.get('comunicados');
        slides.value = resp.data;
        total.value = slides.value.length;
        isLoading.value = false;

        isLoading.value = true;
        const response = await axios.get(`showlayoutsite/1`);
        boasvindas.value = response.data.boasvinda;
        isLoading.value = false;


        cat_pub.value= response.data.carousel;

        const apicategoria = await axios.get(`getallcatlinks/${cat_pub.value}`);
              categoriaLinks.value = apicategoria.data;

               
                links.value = [];
                categoriaLinks.value.forEach( (v, i) => {
              
                    if (v['cat_id']==cat_pub.value)
                    {
                      links.value.push(v);
                    }
                 
                });
                

        filteredLinksPubCard.value = links.value.slice(0, filters.page * perPage);   
        lastPagePub.value = Math.ceil(links.value.length / perPage);


        if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
            serverimg.value = process.env.VUE_APP_SERVER_API_EXTERNA;
        else 
            serverimg.value = process.env.VUE_APP_SERVER_API;

       // Pega os links atribuidos ao usuario
       // const {data} = await axios.get('showalllinks');

        const {data}  = await axios.get(`getuserlinks/${user.value.id}`);

        allLinks.value = data;

        if (allLinks.value.length > 0)
          showMeuslinks.value = true;
         
        
        filteredLinks.value = data.slice(0, filters.page * perPage);   
        lastPage.value = Math.ceil(data.length / perPage);

        const resp_postCardFixo = await axios.get('postcardfixocomunicados');
        postCardFixo.value = resp_postCardFixo.data;

        const resp_postCard = await axios.get('postcardcomunicados');
        postCard.value = resp_postCard.data;
        totalcard.value = postCard.value.length;
        

      });


      const filtersChanged = (f) => {
          filters.s = f.s;
        //  filters.sort = f.sort;
          filters.page = f.page;

          let links= allLinks.value.filter((p)=> (p.short_name ? p.short_name :'').toLowerCase().indexOf(filters.s.toLowerCase()) >= 0 ||
                                                      (p.long_name ? p.long_name : '').toLowerCase().indexOf(filters.s.toLowerCase()) >= 0);

          lastPage.value = Math.ceil(links.length / perPage);
          filteredLinks.value = links.slice(0, filters.page * perPage);
      }

      const filtersPubChanged = (f) => {
          filterspub.s = f.s;
          filterspub.page = f.page;

          let linksloc= links.value.filter((p)=> (p.short_name ? p.short_name :'').toLowerCase().indexOf(filterspub.s.toLowerCase()) >= 0 ||
                                                      (p.long_name ? p.long_name : '').toLowerCase().indexOf(filterspub.s.toLowerCase()) >= 0);

          lastPagePub.value = Math.ceil(linksloc.length / perPage);
          filteredLinksPubCard.value = linksloc.slice(0, filterspub.page * perPage);

      }

      const meustreinamentos = async () => {
         //   await router.push('/meustreinamentos');
            
      }



      const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');

      return {
        filteredLinks,
        filters,
        lastPage,
        filtersChanged,
        slides,
        total,
        serverimg,
        user,
        isLoading,
        onCancel,
        boasvindas,
        filteredLinksPubCard,
        filterspub,
        lastPagePub,
        filtersPubChanged,
        showMeuslinks,
      //  meustreinamentos,
        postCardFixo,
        postCard,
        totalcard
      }

    }
}
</script>

<style>

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);

}
.home {
  padding-right: 0px;
  padding-left: 0px;
}

.img-anime:hover
{
        /* -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
                animation-iteration-count: 1;
        transition:all 0.7s ease;
        -webkit-animation-iteration-count: 1
         */
        
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        animation-iteration-count: 1;
        transition:all 0.6s ease;
        -webkit-animation-iteration-count: 1

}
</style>


