
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:
Ano: 2022
Objetivo: Listar as contas de emails utilizadas pelo usuário para fazer envio de email

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";

export default {
    name:"SmtpsTable",
    setup() {
        const smtps = ref<any[]>([]);

        const acesso = reactive({
            btnInsert:false,
            btnAlter:false,
            btnDelete:false,
            
        });

        const filteredTable = ref(<any>[]);
        const dados = reactive({
            totalreg:0, 
            urlimg:''    
        });

        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const store = useStore();
        const userlogin = computed( () => store.state.user);

        const router = useRouter();
        const route = useRoute();

        onMounted( async () => {

          if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
            {
              dados.urlimg = process.env.VUE_APP_SERVER_API_EXTERNA;        
            }

            else 
            {
              dados.urlimg = process.env.VUE_APP_SERVER_API;
              
            }

            loadAcessos();
            loadtable();
        });


        const del = async (id: number) => {
                if (confirm('Deseja excluir?')) {
                    await axios.delete(`consmtp/${id}`);
                    //console.log(id);
                    smtps.value = smtps.value.filter((p: any) => p.id !== id);
                      loadtable();
                }
        }

         const loadAcessos = async () => {
          //   console.log(userlogin.value.id);
        

            if (route.params.t=== 'f'){
                const resp = await axios.get(`btngetshowuserfunc/${route.params.id}/${userlogin.value.id}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;
            }else if (route.params.t=== 'p'){

                const resp = await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;

            }else {
                acesso.btnInsert = false;
                acesso.btnAlter = false;
                acesso.btnDelete = false;
            }

        }

        const loadtable =async () => {
            const {data} = await axios.get(`listasmtps/${userlogin.value.id}`);
            smtps.value = data;
            dados.totalreg = smtps.value.length;
          console.log(smtps.value)
           filteredTable.value = smtps.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(smtps.value.length / perPage.value);
        }

        const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(smtps.value.length / perPage.value);
           filteredTable.value = smtps.value.slice(0, page.value * perPage.value);

      }

        const search = (s: string ) => {

            filteredTable.value = smtps.value.filter(p=> (p.name?p.name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.address?p.address:'').toLowerCase().indexOf(s.toLowerCase()) >= 0  );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
        }


        return {
            smtps,
            del,
            userlogin,
            acesso,
            filteredTable,
            search,
            lastPage,
            perPage,
            page,
            loadMore,
            dados,
            route             
        }
    }

}
