<template>
<div> 
<h2> {{msg}}</h2>


</div>
</template>

<script>
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Será chamado toda vez que alguem tentar acessar a rota sem estar logado. Algumas funções possuem esta restrição implementada, outras não.


*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import axios from "axios";

export default {
    name: "AccessDenied",
    data(){
        return {
            msg:'Acesso não autorizado! Fale com o Administrador.',
        }
    },

}
</script>

