
    /*
    Autor: Patrick Alex Maia Duarte
    Ref Autor: https://patrickduarte.github.io
    RDM:
    Ano: 2022
    Objetivo: Mostrar as notifications pertencentes a gestão de um site
    
    */
    
    /**Vai fazer mudanças? Então identifique-se e descreva objetivo */
    
    /**
     * Autor:
     * Ref Autor:
     * RDM:
     * Data:
     * Objetivo:
     */
    import {computed,reactive,onMounted, watch, ref} from "vue";
    import {useRoute, useRouter} from "vue-router";
    import {useStore} from "vuex";
    import axios from "axios";
    
    export default {
        name:"SiteNotification",
        setup() {
            const notifications = ref<any[]>([]);
    
            const acesso = reactive({
                btnInsert:false,
                btnAlter:false,
                btnDelete:false,
                
            });
    
            const filteredTable = ref(<any>[]);

    
            const lastPage = ref( 0);
            const perPage = ref(9);
            const page = ref(1);
    
            const store = useStore();
            const userlogin = computed( () => store.state.user);
            const dados = reactive({
                totalreg:0, 
                urlimg:'',
                user_id:userlogin.value.id    
            });
    
            const router = useRouter();
            const route = useRoute();
            const carregou = ref(0);

            const contSub = ref(0);

            const dadosnoti = reactive({
                id:0,                             
                imglogo:'',
                title:'',
                mensagem:'',
                tag:'',
                url:'',
                user_id:0,
                site_id:0,
                group_id:0,
            });
    
    
            onMounted( async () => {
    
              if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
                {
                  dados.urlimg = process.env.VUE_APP_SERVER_API_EXTERNA;        
                }
    
                else 
                {
                  dados.urlimg = process.env.VUE_APP_SERVER_API;
                  
                }
    
                loadAcessos();
                loadtable();
            });
    
            watch(userlogin, () => {
            if(userlogin.value.id !==null){
                dados.user_id = userlogin.value.id
                if(carregou.value === 0)
                {
                   loadAcessos();
                    loadtable();                   
                    carregou.value = 1                   
              }

             }else{
                console.log('foi para o raiz')
                  router.push('/');
                  
             }
        });
    
            const del = async (id: number) => {
                    if (confirm(`Deseja excluir a notificação ${id}?`)) {
                         await axios.delete(`notification/${id}`);                       
                        notifications.value = notifications.value.filter((p: any) => p.id !== id);
                          loadtable();
                    }
            }
    
            const send = async (id: number) => {

                    if (contSub.value > 0)
                    {
                        if (confirm(`Deseja enviar esta notificação ${id}?`)) {                    
                                notifications.value.forEach( (v:any, i:any) => {                        
                                    if (v['id']== id)
                                    {
                                        dadosnoti.id=v['id']                             
                                        dadosnoti.imglogo=v['imglogo'] 
                                        dadosnoti.title=v['title']                
                                        dadosnoti.mensagem=v['mensagem']
                                        dadosnoti.tag=v['tag'] 
                                        dadosnoti.url=v['url'] 
                                        dadosnoti.user_id=v['user_id'] 
                                        dadosnoti.site_id=v['site_id'] 
                                        dadosnoti.group_id=v['group_id'] 
                                    }                                        
                                });
                                const fetchdata = {
                                        method: 'post',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify(dadosnoti),
                                    };

                                const response = await fetch('https://srvpusher.pdenvia.com/sendnoti.php', fetchdata);

                                // Habilita as duas linhas abaixo se quiser saber o que a api está retornando
                                var cloned = response.clone();
                                console.log('Response: ', await cloned.text());

                        } // Fim do bloco da confirmação

                    }else{
                        alert("O site ainda não tem inscritos para enviar notificação")
                    }

                    loadtable()
                
            }

            

             const loadAcessos = async () => {
              //   console.log(userlogin.value.id);
            
    
                if (route.params.t=== 'f'){
                    const resp = await axios.get(`btngetshowuserfunc/${route.params.id}/${dados.user_id}`);
                    acesso.btnInsert = resp.data.insert === 1 ? true : false;
                    acesso.btnAlter =  resp.data.update === 1 ? true : false;
                    acesso.btnDelete = resp.data.delete === 1 ? true : false;
                }else if (route.params.t=== 'p'){
    
                    const resp = await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`);
                    acesso.btnInsert = resp.data.insert === 1 ? true : false;
                    acesso.btnAlter =  resp.data.update === 1 ? true : false;
                    acesso.btnDelete = resp.data.delete === 1 ? true : false;
    
                }else {
                    acesso.btnInsert = false;
                    acesso.btnAlter = false;
                    acesso.btnDelete = false;
                }
    
            }
    
            const loadtable =async () => {
                const {data} = await axios.get(`sitenotifications/${route.params.siteid}`);

                notifications.value = data[0].nots;
                dados.totalreg = notifications.value.length;

               filteredTable.value = notifications.value.slice(0, page.value * perPage.value);
               lastPage.value = Math.ceil(notifications.value.length / perPage.value);

               const resp = await axios.get(`sitecountsub/${route.params.siteid}`)
               contSub.value = resp.data;
               console.log(contSub.value)
            }
    
            const loadMore = () => {
              page.value = page.value + 1;
              lastPage.value = Math.ceil(notifications.value.length / perPage.value);
               filteredTable.value = notifications.value.slice(0, page.value * perPage.value);
    
          }
    
            const search = (s: string ) => {
    
                filteredTable.value = notifications.value.filter(p=> (p.name?p.name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                          (p.address?p.address:'').toLowerCase().indexOf(s.toLowerCase()) >= 0  );
           
               
               lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
               filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
            }
    
    
            return {
              //  notifications,
                del,
                send,
                userlogin,
                acesso,
                filteredTable,
                search,
                lastPage,
                perPage,
                page,
                loadMore,
                dados,
                route,
                contSub             
            }
        }
    
    }
    