

import {computed,reactive,onMounted, SetupContext,ref,watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";



export default {

    name:"VerAgendaBromail",
    props:['broadid','emails'],
    emits:['set-click'],
    setup(props:any, context: SetupContext) {

        const router = useRouter();
        const route = useRoute();

        const store = useStore();
        const user = computed( () => store.state.user);

        const emails = ref();

        // onMounted( async () => {
        //      emails.value = props.emails.emails
   
        // });

    watch(props, async () => {       


        emails.value = props.emails


        // emails.value = emails.value.emails

        // console.log("emails chegou por parametro")
        // console.log(emails.value)

    });


    const fechar = () =>{
        context.emit('set-click', {
            hidden:true
          });
       //   emails.value =[]

    }

        return {
            emails,
            fechar
        }
    }

}
