
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer cadastro da rota do sistema e configurar o icone de acesso. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

import {useRoute, useRouter} from "vue-router";
import {computed,reactive,onMounted, ref, SetupContext, watch,nextTick} from "vue";
import {useStore} from "vuex";
import axios from 'axios';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import EmailLink from "@/views/Funil/Email/EmailLink.vue";

import { QuillEditor, Delta, Quill } from '@vueup/vue-quill'
// import BlotFormatter from 'quill-blot-formatter'

import '@vueup/vue-quill/dist/vue-quill.snow.css'




export default {
  name: "EmailEdit",
  components:  { Loading,QuillEditor, EmailLink},
  props:['email','groupmails','urls'],
  emits:['set-click'],

  setup(props:any, context: SetupContext) {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    
    const isLoading = ref(false);

    const dadosEmail = ref<any[]>([]);

    const idmail = ref(props.email.id)

    const data = reactive({
      id:props.email.id,
      title: props.email.title,
      from:props.email.from,
      subject:props.email.subject,
      mensage:props.email.mensage,
      enable:props.email.enable==1 ? true : false,
      //code:0, Vai ser gerado pelo sistema
      //intervalo:0, foi substituido por qtd,qth e qtm
      typeaction: props.email.typeaction, // 0 - nada  1 - leitura 2 - click
      qtd:props.email.qtd,
      qth:props.email.qth,
      qtm:props.email.qtm,
      interval:props.email.interval,
      // significa que o email será enviado entre 07:00 a 07:59 da manhã
      //track:'' , situação futura para ser usado junto com ia
      //ia:'', situação futura
      position_seq: props.email.position_seq, // vai ser gerado pelo sistema em relação ao grupo associado e a troca será direta com outro email do mesmo grupo. Exemplo se ele a posição é a 2,
                        // e alterar para 1, então o email que estava na posição passa para 2. Ou seja, troca de posição com o email que tinha a posição da troca 
      restrictdays:props.email.restrictdays,
      mailclick:props.email.mailclick,
      condicional:props.email.condicional, // 0-sequencial 1 - com condição
                    //Quando condicional for zero, os campos mailcheck e mailnoaction devem ficar desabilitados
      mailcheck:props.email.mailcheck, // Codigo do email que vai ser verificado o comportamento do usuário com base no typeaction
      mailnoaction:props.email.mailnoaction, // Codigo do email que vai ser disparado se o comportamento do usuário for nenhum em relação ao definido no mailcheck. 
                     
      user_id:user.value.id,
      group_id:props.email.group_id,
      // isdayfixed:props.email.isdayfixed,
      // dayfixed:props.email.dayfixed
            
    });

    

    const myEditor = ref()
    // const restricao = ref([
    //       {'name':'Dom','value':true },
    //       {'name':'Seg','value':true },
    //       {'name':'Ter','value':true },
    //       {'name':'Qua','value':true },
    //       {'name':'Qui','value':true },
    //       {'name':'Sex','value':true },
    //       {'name':'Sab','value':true }
    //     ])

     const Dom  = ref(true);
     const Seg  = ref(true);
     const Ter  = ref(true);
     const Qua  = ref(true);
     const Qui  = ref(true);
     const Sex  = ref(true);
     const Sab  = ref(true);

     const emails = ref<any[]>([]);

     const links = ref<any[]>([]);

     const urls = ref<any[]>([]);
    

    // const link = reactive({
    //   email_id: 0,
    //   link_id:0,
    //   user_id:0

    // });

    const janela = ref(false);
    const content = ref<Delta>(
      new Delta([
        { insert: 'Gandalf', attributes: { bold: true } },
        { insert: ' the ' },
        { insert: 'Grey', attributes: { color: '#ccc' } },
      ])
    )
 
    
    onMounted( () => {

        janela.value = false;
        urls.value = props.urls;
    
    });


    const dias = ref()
  
    watch(props, async () => {

      //  console.log('buscar dados do email')
      //  console.log(props.email)
        // console.log(props.email.id)

      if (props.email.title != null)
      {
        data.id = props.email.id
        data.title= props.email.title 
        data.from=props.email.from==null ? '' : props.email.from;
        data.subject=props.email.subject;

        data.mensage = props.email.mensage
       // console.log(data.mensage)
        myEditor.value.setHTML(props.email.mensage)

        data.enable=props.email.enable;
        data.typeaction= props.email.typeaction;
        data.qtd=props.email.qtd;
        data.qth=props.email.qth;
        data.qtm=props.email.qtm;
        data.interval=props.email.interval;
        data.position_seq=props.email.position_seq; 
        data.restrictdays=props.email.restrictdays;

        idmail.value = props.email.id

        dias.value = props.email.restrictdays.replace(',','')
        dias.value = (dias.value).split("")

        // dias.value = (props.email.restrictdays).split(",")
        //  console.log(dias.value)

        links.value = props.email.links


//        console.log(checkDia(0))

        //A tratatia dos dias restrito já deve vir no props, para não ter o bug da inicialização da primeira tela.
        Dom.value = false
        Seg.value = false
        Ter.value = false
        Qua.value = false
        Qui.value = false
        Sex.value = false
        Sab.value = false

        dias.value.forEach( (v:any,i:any) => { if (v==0) {  Dom.value = true  } });
        dias.value.forEach( (v:any,i:any) => { if (v==1) {  Seg.value = true  } });
        dias.value.forEach( (v:any,i:any) => { if (v==2) {  Ter.value = true  } });
        dias.value.forEach( (v:any,i:any) => { if (v==3) {  Qua.value = true  } });
        dias.value.forEach( (v:any,i:any) => { if (v==4) {  Qui.value = true  } });
        dias.value.forEach( (v:any,i:any) => { if (v==5) {  Sex.value = true  } });
        dias.value.forEach( (v:any,i:any) => { if (v==6) {  Sab.value = true  } });


        data.condicional=props.email.condicional;
        showcond.value = props.email.condicional==1 ? true : false;
        showAction.value = props.email.condicional==1 ? true : false;

        data.mailcheck=props.email.mailcheck;
        data.mailnoaction=props.email.mailnoaction;                    
        data.group_id=props.email.group_id;
        data.user_id=props.email.user_id;

        emails.value = props.groupmails;

        
      } 
      // data.isdayfixed = props.email.position_seq==0 ? true : false;
      urls.value = props.urls

    });

      //Atualiza os links do email após um update
      const uplinksdata= async () => {

                const resp = await axios.get(`emailinks/${props.email.id}`);               
                links.value = resp.data;
    }
    
    const fechar = () =>{
      janela.value = false;

    }


    const showcond = ref(false);

    const condwindow = (id:number)=>{
        if(data.condicional==1)
        {
          showcond.value = false;
        }
        else{
          showcond.value=true;
        }
        
        if (id==1)
        {
          showAction.value=false;
          tipoAction.value=0;
          data.typeaction = 0;
        }

    }

    const showAction = ref(false);
    const tipoAction = ref(0);

    const actionwindow = (id:number)=>{
        if(data.typeaction==0)
        {
          showAction.value = false;
        }
        else{
          showAction.value=true;
        }
        tipoAction.value = id;
        data.mailcheck=0;
        data.mailnoaction=0;
    }

      
    const intervalo = ref([{label:'00:00 - 00:59', value:0},{label:'01:00 - 01:59', value:1},{label:'02:00 - 02:59', value:2},{label:'03:00 - 03:59', value:3},
                           {label:'04:00 - 04:59', value:4},{label:'05:00 - 05:59', value:5},{label:'06:00 - 06:59', value:6},{label:'07:00 - 07:59', value:7},
                           {label:'08:00 - 08:59', value:8},{label:'09:00 - 09:59', value:9},{label:'10:00 - 10:59', value:10},{label:'11:00 - 11:59', value:11},
                           {label:'12:00 - 12:59', value:12},{label:'13:00 - 13:59', value:13},{label:'14:00 - 14:59', value:14},{label:'15:00 - 15:59', value:15},
                           {label:'16:00 - 16:59', value:16},{label:'17:00 - 17:59', value:17},{label:'18:00 - 18:59', value:18},{label:'19:00 - 19:59', value:19},
                           {label:'20:00 - 20:59', value:20},{label:'21:00 - 21:59', value:21},{label:'22:00 - 22:59', value:22},{label:'23:00 - 23:59', value:23}]);
    //const intervalo = ref([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]);

      //   const contentHTML = ref(props.email.mensage)
      //  const contentText = ref('This is just plain text')

    const submit = async () => {
        data.restrictdays=''
        data.restrictdays= data.restrictdays + (Dom.value ? '0,' : '')
        data.restrictdays= data.restrictdays + (Seg.value ? '1,' : '')
        data.restrictdays= data.restrictdays + (Ter.value ? '2,' : '')
        data.restrictdays= data.restrictdays + (Qua.value ? '3,' : '')
        data.restrictdays= data.restrictdays + (Qui.value ? '4,' : '')
        data.restrictdays= data.restrictdays + (Sex.value ? '5,' : '')
        data.restrictdays= data.restrictdays + (Sab.value ? '6' : '')


       
      // Se não for o primeiro email, então , verifica se foi determinado tempo para gerar o segundo email

      
       if (data.position_seq>0){

            if ((data.qtd==0 && data.qth==0  && data.qtm==0) )
            {
                confirm(`Na aba AÇÃO o dia, hora e minutos são igual zero. Porém, precisa que um deles seja diferente de zero.`)
            }
       }


       
       //Valida se as condições do email foram preenchidas corretamente
       if (data.condicional== 1 && data.typeaction==0)
       {
        confirm(`Você selecionou e-mail condicional. Então,preencha os campos da condição para gerar o  próximo email`)
       }
       else{

          if (  Dom.value == false &&
              Seg.value == false &&
              Ter.value == false &&
              Qua.value == false &&
              Qui.value == false &&
              Sex.value == false &&
              Sab.value == false)        
               confirm(`Você precisar deixar ao menos um dia libeado para envio da mensagem`)

            else {

                isLoading.value = true;
                await axios.put(`email/${data.id}`, data);
                isLoading.value = false;
                janela.value = true;
                context.emit('set-click', {
                    hidden:true
                  });
            }
       

       }
       


    }
    
    

  //  const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');
    return {
      data,
      isLoading,
      janela,
    //  onCancel,
      submit,
      fechar,
      showcond,
      condwindow,
      showAction,
      tipoAction,
      actionwindow,
      intervalo,
      Dom,
      Seg,
      Ter,
      Qua,
      Qui,
      Sex,
      Sab,
      content,
      emails,
      myEditor,
      links,
      urls,
      idmail,
      uplinksdata
    }
  }
}
