
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer upload de arquivos de imagem

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import axios from 'axios';
import {SetupContext} from 'vue';

export default {
  name: "ImageUpload",
  emits: ['uploaded'],
  setup(props: any, context: SetupContext) {

    const upload = async (files: FileList | null) => {
      if (files === null) return;

      const formData = new FormData();
      formData.append('image', files[0]);

      const {data} = await axios.post('upload', formData);

      context.emit('uploaded', data.url);
    }

    return {
      upload
    }
  }
}
