
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:
Ano: 2022
Objetivo: Fazer edição dos links do sistema

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';



export default {
  name: "SmtpEdit",
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      name: '',
      address: '',
      driver: '',
      host: '',
      port: '',
      encryption:'',
      username:'',
      password:'',
      user_id:'',
      msg:'',
      success:false,
      adduserok:false,
      errorAddUser:false,
      error:false,
      achou:false
    });

    onMounted(async () => {

     checkAcesso();

      const response = await axios.get(`consmtp/${route.params.smtpid}`);

      data.name = response.data.name;
      data.address= response.data.address;
      data.driver= response.data.driver;
      data.host= response.data.host;
      data.port= response.data.port;
      data.encryption= response.data.encryption;
      data.username= response.data.username;
      data.password= response.data.password;
      data.user_id= response.data.user_id;
      
    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }

    const submit = async () => {
      await axios.put(`consmtp/${route.params.smtpid}`, data);

        data.msg= 'Conexão SMTP atualizada!';
        data.success = true;
        setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,500
                );

    }

    const voltar = async () => {
       if (route.params.t=== 'f')
          await router.push(`/smtp/${route.params.t}/${route.params.id}/${0}`);
      else if (route.params.t=== 'p'){
        await router.push(`/smtp/${route.params.t}/${route.params.id}/${route.params.perfil}`);
      }

    }

    return {
      data,
      submit,
      voltar

    }
  }
}


