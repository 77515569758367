
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer cadastro da rota do sistema e configurar o icone de acesso. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

import {useRoute, useRouter} from "vue-router";
import {computed,reactive,onMounted, ref, SetupContext, watch,nextTick} from "vue";
import {useStore} from "vuex";
import axios from 'axios';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { QuillEditor, Delta } from '@vueup/vue-quill'
// import BlotFormatter from 'quill-blot-formatter'

import '@vueup/vue-quill/dist/vue-quill.snow.css'




export default {
  name: "EmailCreate",
  components:  { Loading,QuillEditor},
  props:['grupoid','position'],
  emits:['set-click'],

  setup(props:any, context: SetupContext) {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    
    const isLoading = ref(false);

       const content = ref<Delta>(
      new Delta([
        { insert: 'Gandalf', attributes: { bold: true } },
        { insert: ' the ' },
        { insert: 'Grey', attributes: { color: '#ccc' } },
      ])
    )



    const data = reactive({
      title: '',
      from:'',
      subject:'',
      mensage:'<p>Oi {nome},  aqui </p><p><br></p><p><span style="color: rgb(0, 97, 0);" class="ql-font-monospace ql-size-large">coloque sua mensagem aqui</span></p><p><br></p><p>Patrick aqui é sua nova chance de ganhar um novo Lead.</p><p><br></p><p class="ql-align-center"><span style="color: rgb(230, 0, 0);" class="ql-size-large">Não perca essa promoção</span></p>',
      enable:false,
      //code:0, Vai ser gerado pelo sistema
      //intervalo:0, foi substituido por qtd,qth e qtm
      typeaction:0, // 0 - nada  1 - leitura 2 - click
      qtd:0,
      qth:0,
      qtm:0,
      interval:7,
      // significa que o email será enviado entre 07:00 a 07:59 da manhã
      //track:'' , situação futura para ser usado junto com ia
      //ia:'', situação futura
      position_seq: props.position, // vai ser gerado pelo sistema em relação ao grupo associado e a troca será direta com outro email do mesmo grupo. Exemplo se ele a posição é a 2,
                        // e alterar para 1, então o email que estava na posição passa para 2. Ou seja, troca de posição com o email que tinha a posição da troca 
      restrictdays:'',
      mailclick:0,
      condicional:0, // 0-sequencial 1 - com condição
                    //Quando condicional for zero, os campos mailcheck e mailnoaction devem ficar desabilitados
      mailcheck:0, // Codigo do email que vai ser verificado o comportamento do usuário com base no typeaction
      mailnoaction:0, // Codigo do email que vai ser disparado se o comportamento do usuário for nenhum em relação ao definido no mailcheck. 
                     
      user_id:user.value.id,
      group_id:props.grupoid,
            
    });

    const restricao = ref([
          {'name':'Dom','value':true },
          {'name':'Seg','value':true },
          {'name':'Ter','value':true },
          {'name':'Qua','value':true },
          {'name':'Qui','value':true },
          {'name':'Sex','value':true },
          {'name':'Sab','value':true }
        ])

     const Dom  = ref(true);
     const Seg  = ref(true);
     const Ter  = ref(true);
     const Qua  = ref(true);
     const Qui  = ref(true);
     const Sex  = ref(true);
     const Sab  = ref(true);
     const emails = ref<any[]>([]);

    // const rest = ref<any>([]);

    const link = reactive({
      email_id: 0,
      link_id:0,
      user_id:0

    });

    const janela = ref(false);
 

    onMounted( () => {

 janela.value = false;
      // rest.value = data.restrictdays.split("");
      // console.log(rest)
      janela.value = false;

      
      // data.mlist_id = parseInt(props.listaid);
          
    });

    watch(props, async () => {

            if(props.grupoid !==null){

                data.group_id = props.grupoid;
                data.position_seq =  props.position;
                data.user_id = user.value.id;
                // console.log('novo valor de position seq')
                // console.log(data.position_seq)
             }
      isLoading.value = true;
          // console.log('aqui foi consultado')
      const resp= await axios.get(`emailsgroup/${data.group_id}/${data.user_id}`);                      
      emails.value = resp.data[0].emailsorder;
 
     // console.log(emails)
      isLoading.value = false;

    });


    
    const fechar = () =>{
      janela.value = false;

      Dom.value = true;
      Seg.value = true;
      Ter.value = true;
      Qua.value = true;
      Qui.value = true;
      Sex.value = true;
      Sab.value = true;

      data.title= ''; 
      data.from='';
      data.subject='';
      data.mensage='Digite aqui seu texto';
      data.enable=false;
      data.typeaction;
      data.qtd=0;
      data.qth=0;
      data.qtm=0;
      data.interval=7;
      data.position_seq=props.position; 
      data.restrictdays='';
      data.condicional=0;
      data.mailcheck=0;
      data.mailnoaction=0;                    
      data.group_id=props.grupoid;
      data.user_id=user.value.id;
     

    }


    const showcond = ref(false);

    const condwindow = (id:number)=>{
        if(data.condicional==1)
        {
          showcond.value = false;
        }
        else{
          showcond.value=true;
        }
        
        if (id==1)
        {
          showAction.value=false;
          tipoAction.value=0;
          data.typeaction = 0;
        }

    }

    const showAction = ref(false);
    const tipoAction = ref(0);

    const actionwindow = (id:number)=>{
        if(data.typeaction==0)
        {
          showAction.value = false;
        }
        else{
          showAction.value=true;
        }
        tipoAction.value = id;
        data.mailcheck=0;
        data.mailnoaction=0;
    }
      
    const intervalo = ref([{label:'00:00 - 00:59', value:0},{label:'01:00 - 01:59', value:1},{label:'02:00 - 02:59', value:2},{label:'03:00 - 03:59', value:3},
                           {label:'04:00 - 04:59', value:4},{label:'05:00 - 05:59', value:5},{label:'06:00 - 06:59', value:6},{label:'07:00 - 07:59', value:7},
                           {label:'08:00 - 08:59', value:8},{label:'09:00 - 09:59', value:9},{label:'10:00 - 10:59', value:10},{label:'11:00 - 11:59', value:11},
                           {label:'12:00 - 12:59', value:12},{label:'13:00 - 13:59', value:13},{label:'14:00 - 14:59', value:14},{label:'15:00 - 15:59', value:15},
                           {label:'16:00 - 16:59', value:16},{label:'17:00 - 17:59', value:17},{label:'18:00 - 18:59', value:18},{label:'19:00 - 19:59', value:19},
                           {label:'20:00 - 20:59', value:20},{label:'21:00 - 21:59', value:21},{label:'22:00 - 22:59', value:22},{label:'23:00 - 23:59', value:23}]);
    //const intervalo = ref([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]);

        const contentHTML = ref('<h1>This is html header</h1>')
       const contentText = ref('This is just plain text')

    const submit = async () => {
        data.restrictdays=''
        data.restrictdays= data.restrictdays + (Dom.value ? '0,' : '')
        data.restrictdays= data.restrictdays + (Seg.value ? '1,' : '')
        data.restrictdays= data.restrictdays + (Ter.value ? '2,' : '')
        data.restrictdays= data.restrictdays + (Qua.value ? '3,' : '')
        data.restrictdays= data.restrictdays + (Qui.value ? '4,' : '')
        data.restrictdays= data.restrictdays + (Sex.value ? '5,' : '')
        data.restrictdays= data.restrictdays + (Sab.value ? '6' : '')

      //   console.log(content.value) 

        //  console.log(data.restrictdays.replaceAll('',','))
        //  console.log(data.restrictdays.replaceAll('',','))

       //Valida se as condições do email foram preenchidas corretamente


       if ((data.qtd==0 && data.qth==0  && data.qtm==0) )
       {
          confirm(`Na aba AÇÃO o dia, hora e minutos são igual zero. Porém, precisa que um deles seja diferente de zero.`)
       }

       if (data.condicional== 1 && data.typeaction==0)
       {
        confirm(`Você selecionou e-mail condicional. Então,preencha os campos da condição para gerar o  próximo email`)
       }
       else{
             //Condição adicionada para não deixar acontecer o erro de não ter um dia selecionado
            if (  Dom.value == false &&
              Seg.value == false &&
              Ter.value == false &&
              Qua.value == false &&
              Qui.value == false &&
              Sex.value == false &&
              Sab.value == false)        
               confirm(`Você precisar deixar ao menos um dia libeado para envio da mensagem`)

            else {
       
              //console.log(data.mensage)
                isLoading.value = true;
                await axios.post('email', data);
                isLoading.value = false;
                janela.value = true;
                context.emit('set-click', {
                    hidden:true
                  });
            }
       }
       


    }
    
    

    const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');
    return {
      data,
      isLoading,
      janela,
      onCancel,
      submit,
      fechar,
      showcond,
      condwindow,
      showAction,
      tipoAction,
      actionwindow,
      intervalo,
      restricao,
      Dom,
      Seg,
      Ter,
      Qua,
      Qui,
      Sex,
      Sab,
      content,
      contentHTML,
      contentText,
      emails

    }
  }
}
