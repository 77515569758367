import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
 import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
 import {library} from '@fortawesome/fontawesome-svg-core';
 import { fas } from  '@fortawesome/free-solid-svg-icons';
 import 'bootstrap-icons/font/bootstrap-icons.css'
 import { VueReCaptcha } from "vue-recaptcha-v3";





library.add(fas);

// console.log('Nome de servidor');
// console.log(window.location.hostname);

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
//   axios.defaults.baseURL = process.env.VUE_APP_SERVER_API_EXTERNA + 'a/sistema/'; //aqui deve ser https://pdenvia.com.br/a/sistema/ (precisa ser visto direito)
// else 
//   axios.defaults.baseURL = process.env.VUE_APP_SERVER_API + 'a/sistema/'; 

axios.defaults.baseURL = process.env.VUE_APP_SERVER_API + 'a/sistema/'; 
axios.defaults.withCredentials = true;

createApp(App)
.component("font-awesome-icon", FontAwesomeIcon)
.use(store).use(router).use(VueReCaptcha, { siteKey: '6LdJ7xApAAAAADRmfQtrpIndKS1MljyRlX-ela4v' }).mount('#app')



//deploy fontawesome
//https://www.youtube.com/watch?v=MoDIpTuRWfM

//https://fontawesome.com/v5.15/icons?d=gallery&p=2    // galeria de icons

//https://codepen.io/tutsplus/pen/bGVPVrY   - menu