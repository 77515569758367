
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Listar as funções cadastradas no sistema.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted,watch,ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";

export default {
    name:"SitesTable",
    setup() {
       
        const sites = ref<any[]>([]);
        

        const acesso = reactive({
            btnInsert:false,
            btnAlter:false,
            btnDelete:false,
            
        });
        
        const filteredTable = ref(<any>[]);

        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const store = useStore();
        const userlogin = computed( () => store.state.user);

        const user = ref(userlogin.value.id);

        const dados = reactive({
            user_id: userlogin.value.id,
            totalreg:0,     
        });

        const router = useRouter();
        const route = useRoute();
        const carregou = ref(0)

        onMounted( async () => {
            
            loadAcessos();
        //    console.log('chamada de loadtable na montagem')
            loadtable();
        });


        watch(userlogin, () => {
            if(userlogin.value.id !==null){
                dados.user_id = userlogin.value.id
              //  console.log('carregou.value')
              //  console.log(carregou.value)

                if(carregou.value === 0)
                {
                   // console.log(carregou.value)
                   loadAcessos();
                    loadtable();                   
                   // loadLista();
                  //  menu.value = 'Campanha';
                    carregou.value = 1
                    
              }

             }else{
                console.log('foi para o raiz')
                  router.push('/');
                  
             }
        });




        const del = async (id: number) => {
                if (confirm(`Deseja excluir Lista ${id}?`)) {
                   
                    const resp = await axios.delete(`lista/${id}`);
                 //    console.log(resp.data);

                   loadtable();
                }
        }

        const loadAcessos = async () => {
          //   console.log(userlogin.value.id);
        

            if (route.params.t=== 'f'){
                const resp = await axios.get(`btngetshowuserfunc/${route.params.id}/${dados.user_id}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;
            }else if (route.params.t=== 'p'){

                const resp = await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;

            }else {
                acesso.btnInsert = false;
                acesso.btnAlter = false;
                acesso.btnDelete = false;
            }

        }


        const loadtable =async () => {

           // console.log('id de login igual a ');
           // console.log(userlogin.value.id);
        //   console.log('user_id');
         //  console.log(user.value);
       //    console.log(userlogin.value.id);

            const {data} = await axios.get(`sitetable/${dados.user_id}`); // Para mostrar somente sites pertencente ao usuário

        //   console.log('lista');
       //    console.log(data);

            sites.value = data;
            dados.totalreg = sites.value.length;

       //     console.log('lista')
          

           filteredTable.value = sites.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(sites.value.length / perPage.value);
        }

        const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(sites.value.length / perPage.value);
           filteredTable.value = sites.value.slice(0, page.value * perPage.value);

      }

        const search = (s: string ) => {

            filteredTable.value = sites.value.filter(p=> (p.name?p.name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.description?p.description:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
        }


        const ativar = async (id: number) => {
                if (confirm('Deseja Ativar essa Lista?')) {
                    await axios.put(`ativarlista/${id}`);
                    loadtable();
                }
        }

        const inativar = async (id: number) => {
                if (confirm('Deseja Inativar Lista?')) {
                    await axios.put(`inativarlista/${id}`);
                    loadtable();
                }
        }

        return {
            del,
            ativar,
            inativar,
            userlogin,
            acesso,
            filteredTable,
            search,
            lastPage,
            perPage,
            page,
            loadMore,
            dados,
            route           
        }
    }

}
