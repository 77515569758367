
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Componente importante para montar os links internos (meus links) e externos na pagina home e de entrada do sistema.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {ref,onMounted, SetupContext} from 'vue';
import axios from 'axios';

export default {
    name:"LinksCard",
    props: ['plinks','filters','lastPage'],
    emits:['set-filters'],
    setup(props: any, context: SetupContext){

      const selected = ref<number[]>([]);
      const link = ref('');
      const error = ref('');
      const serverimg = ref('');
      const origemserv = ref(0);

      const search = (s: string ) => {
        context.emit('set-filters', {
          ...props.filters,
          s,
          page:1
        });
      }

      onMounted( () => {

        if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO){
            serverimg.value = process.env.VUE_APP_SERVER_API_EXTERNA;
            origemserv.value = 1;
        }
        else
        {
          serverimg.value = process.env.VUE_APP_SERVER_API;
          origemserv.value = 0;
        } 
            

      });


      const loadMore = () => {
        context.emit('set-filters', {
          ...props.filters,
          page: props.filters.page + 1
        });

      }


       const openurl = (url: string) => {
     //    console.log("clicou mais não abriu");
         window.open(url, '_blank');

      }

      return {
        selected,
        link,
        error,
        search,
        loadMore,
        openurl,
        serverimg,
        origemserv

      }
    }

}
