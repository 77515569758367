
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer cadastro da rota do sistema e configurar o icone de acesso. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

import {useRoute, useRouter} from "vue-router";
import { computed,reactive,onMounted, ref, SetupContext, watch,nextTick} from "vue";
import {useStore} from "vuex";
import axios from 'axios';


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: "BromailLink",
  components:  { Loading},
  props:['emailid','links','urls'],
  emits:['update-links'],

  setup(props:any, context: SetupContext) {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    //const { toClipboard } = useClipboard()
    const cliptext = ref('')
    
    const isLoading = ref(false);

     const showlinks = ref(false)

    const editlinkshow = ref(false)
    const chooselk = ref(0)
    

    const data = reactive({
      id:0,
       tag:'',
       label:'',
       link_id:0,
       email_id:0,
       user_id:user.value.id

      // id:props.email.id,
      // title: props.email.title,
      // from:props.email.from,
      // subject:props.email.subject,
      // mensage:props.email.mensage,
      // enable:props.email.enable==1 ? true : false,
                  
    });

 


    const janela = ref(false);

    const links = ref<any[]>([]);
    const urls = ref<any[]>([]);
    
    onMounted( () => {

        janela.value = false;
    
    });


  
    watch(props, async () => {

      

      if (props.emailid != null)
      {

        if (props.links.length> 0 )
        {
           showlinks.value = true
           links.value = props.links
        }
        else
           showlinks.value = false      

      }

     urls.value = props.urls   
     data.user_id = user.value.id

    });


    
    const fechar = () =>{
      janela.value = false;

    }


    const showcond = ref(false);

    const showNewLinkForm = () => {
      showlinks.value = false;
      janela.value = true;
          data.tag= ''
          data.label=''
          data.link_id=0
          
    }

    const cancelcadlink = () => {
      janela.value = false
        if (props.links.length> 0 )
           showlinks.value = true
        else
           showlinks.value = false
    }

    const AlterarLink = (id:any) => {
      editlinkshow.value= false
      chooselk.value=id

      links.value.forEach( (v:any,i:any) => {
        if (v['id']==id)
        {
          data.id= v['id']
          data.tag= v['tag']
          data.label=v['label']
          data.link_id=v['link_id']
          data.email_id=v['bromail_id']
          data.user_id = user.value.id
        }
      });
    }

    const CancelarAlterarLink = () => {
      chooselk.value= 0
    }

    const CopyLink = async (id:any) => {

        links.value.forEach( (v:any,i:any) => {
          if (v['id']==id)
          {
            data.tag= v['tag']
            data.label=v['label']
            data.link_id=v['link_id']
          }
        });
        cliptext.value = `{${data.tag}}`

       //   await toClipboard(cliptext.value)


          /* Alert the copied text */
        //alert("A tag é " + text.value);
    }

    const ExcluirLink = async (id:any,tag:any) => {

      if (confirm(`deseja excluir a Tag  ${tag}? Se sim, retire a tag do email também ou substitua por outra`)) {
            isLoading.value = true;
            await axios.delete(`bromailink/${id}`);
            isLoading.value = false;
       }
       
       context.emit('update-links', {
                    hidden:true
       });
    }


    const SalvarAlterarLink = async () => {
                isLoading.value = true;
                await axios.put(`bromailink/${data.id}`, data);
                isLoading.value = false;
                chooselk.value= 0;

                context.emit('update-links', {
                    hidden:true
                });

    }

    const CriarLink = async () => {

           if (data.tag=='' || data.label=='' || data.link_id==0)
           {
            confirm(`Verifique se o campo Tag, label ou url estão vazio, e preencha-os`)
           }
           else{
                data.user_id = user.value.id
                data.email_id = props.emailid
                isLoading.value = true;
                await axios.post('bromailink', data);
                isLoading.value = false;
                chooselk.value= 0;

                context.emit('update-links', {
                    hidden:true
                });

              data.tag= ''
              data.label=''
              data.link_id=0

              janela.value = false
              if (props.links.length> 0 )
                showlinks.value = true
              else
                showlinks.value = false
              }


    }
    

   // const submit = async () => { 
                // isLoading.value = true;
                // await axios.put(`email/${data.id}`, data);
                // isLoading.value = false;
                // janela.value = true;
                // context.emit('set-click', {
                //     hidden:true
                //   });
     
   // }
    
    

  //  const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');
    return {
      data,
      isLoading,
      janela,
      urls,
    //  onCancel,
     // submit,
      fechar,
      showcond,
      showlinks,
      showNewLinkForm,
      cancelcadlink,
      editlinkshow,
      CriarLink,
      AlterarLink,
      SalvarAlterarLink,
      CancelarAlterarLink,
      ExcluirLink,
      CopyLink,
      chooselk,
      cliptext


    }
  }
}
