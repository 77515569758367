
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:
Ano: 2022
Objetivo: Formulário para criar um cadastro de comunicados

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:Patrick Duarte
 * Ref Autor: https://patrickduarte.github.io
 * RDM: 2022000049
 * Data: 15/06/2022
 * Objetivo: Alterar função comunicado para abrir pagina web ao clicar no slide. E também incluir componente card e card fixo no top, para ser uma alternativa de mostrar
 *           comunicados. Eles ficarão integrado com o blog da intranet.
 */
import {useRoute, useRouter} from "vue-router";
import {computed,reactive,onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from 'axios';
import ImageUploadcp from "@/components/ImageUploadcp.vue";

export default {
  name: "ComunicadoCreate",
  components: {ImageUploadcp},
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      short_name: '',
      long_name: '',
      note: '',
      url:'',
      iscard: false,
      iscardtop: false,
    //   namefile: '',
      image: '',
      user_action: 1
    });


    onMounted(async () => {
      
          checkAcesso();

    });

    const checkAcesso = async () => {

        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.insert !== 1 ? true : false )
                await router.push('/acessonegado');

        }else if (route.params.t=== 'p'){
            
               if (!(user.value.id > 0))
                 await router.push('/acessonegado');

              const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.insert !== 1 ? true : false )
                await router.push('/acessonegado');
        }else {
            await router.push('/acessonegado');
        }
       
    }

    const checkiscard= (valor:any) =>{ 
             data.iscard= valor==false ? data.iscard=true : data.iscard=false;  
            // console.log(data.iscard); 
           }
    
    const checkiscardtop= (valor:any) =>{ 
             data.iscardtop= valor==false ? data.iscardtop=true : data.iscardtop=false;  
            // console.log(data.iscard); 
    }

    const submit = async () => {
      await axios.post('comunicados', data);

       if (route.params.t=== 'f')
          await router.push(`/comunicados/${route.params.t}/${route.params.id}/${0}`);
      else if (route.params.t=== 'p'){
        await router.push(`/comunicados/${route.params.t}/${route.params.id}/${route.params.perfil}`);
      }

    }

    return {
      data,
      checkiscard,
      checkiscardtop,
      submit
    }
  }
}
