
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Pagina inicial externa que só é visualizada para quem está dentro da rede interna.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:Patrick Duarte
 * Ref Autor: https://patrickduarte.github.io
 * RDM: 2022000049
 * Data: 15/06/2022
 * Objetivo: Alterar função comunicado para abrir pagina web ao clicar no slide. E também incluir componente card e card fixo no top, para ser uma alternativa de mostrar
 *           comunicados. Eles ficarão integrado com o blog da intranet.
 */
import LinksCard from "@/views/Links/LinksCard.vue";

import {reactive,onMounted,computed, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import Comunicado from '@/components/Comunicado.vue';
import Link from '@/components/Link.vue';


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    name:"Inicio",
    components: { Comunicado, Link,Loading,  LinksCard},  
      
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const serverimg = ref('');
        const apiData = reactive({

              urlimg: process.env.VUE_APP_SERVER_API,
              
          });
      const filters = reactive({
        s:'',
        sort:'',
        page:1
      });

      const lastPage = ref(0);
      const perPage = 12;
            const allLinks = ref([]);
      const filteredLinks = ref([]);

      const filteredLinksCard = ref([]);

      const data = reactive({
      rodape: '',
      nome_sistema: '',
      boasvinda:'',
      showcarousel:false,
      col_um: 0,
      col_dois: 0,
      col_tres: 0,
      col_quatro:0,
      facebook:0,
      instagram:0,
      totalcard:0,
      logo:'',
      msg:'',
      success:false,
      error:false,
      serverimg:'',//process.env.VUE_APP_SERVER_API,
      cats:'',
      label_rod1:'',
      label_rod2:'',
      label_rod3:'',
      label_rod4:'',
      url_inicial:'',
      origem:0
    });

        const categoriaLinks = ref(<any>[]);
        const links = ref(<any>[]);

        const isLoading = ref(false);

        const rodape1= ref(<any>[]);
        const rodape2= ref(<any>[]);
        const rodape3= ref(<any>[]);
        const rodape4= ref(<any>[]);
        const cat_pub = ref(0);


        //const cats= ref(<any>[]);

        const instagram= ref(<any>[]);
        const facebook= ref(<any>[]);

        const postCardFixo= ref(<any>[]);

        const postCard= ref(<any>[]);


 
        onMounted(async () => {

            if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
            {
              data.serverimg = process.env.VUE_APP_SERVER_API_EXTERNA;
              data.url_inicial = process.env.VUE_APP_URL_EXTERNO;
              data.origem = 1;
              await router.push('/login');
            }

            else 
            {
              data.serverimg = process.env.VUE_APP_SERVER_API;
               data.url_inicial = process.env.VUE_APP_URL_INTERNO;
              data.origem = 0;
            }

          
            // const resp_postCardFixo = await axios.get('postcardfixocomunicados');
            // postCardFixo.value = resp_postCardFixo.data;

            // const resp_postCard = await axios.get('postcardcomunicados');
            // postCard.value = resp_postCard.data;
            // data.totalcard = postCard.value.length;
            

          // isLoading.value = true;
          //   const orin_acess = await axios.get('getoriginacess');
          // isLoading.value = false;

          await store.dispatch('setUser', null);
          // isLoading.value = true;

          // const response = await axios.get(`showlayoutsite/1`);
          // isLoading.value = false;
          // data.rodape = response.data.rodape;
          // data.nome_sistema= response.data.nome_sistema;
          // data.boasvinda= response.data.boasvinda;
          // data.showcarousel = response.data.showcarousel == 1 ? true : false;
          // data.col_um= response.data.col_um;
          // data.col_dois = response.data.col_dois;
          // data.col_tres = response.data.col_tres;
          // data.col_quatro = response.data.col_quatro;
          // data.facebook = response.data.facebook;
          // data.instagram = response.data.instagram;
          // data.logo = response.data.logo;


           // cat_pub.value= response.data.carousel;

          // cat_pub.value= 1;

          // Aqui está o problema do undefined
          //  const apicategoria = await axios.get(`getallcatlinks/${cat_pub.value}`);
          //       categoriaLinks.value = apicategoria.data;

               
          //       links.value = [];
          //       categoriaLinks.value.forEach( (v:any, i:any) => {
              
          //           if (v['cat_id']==cat_pub.value)
          //           {
          //             links.value.push(v);
          //           }
                 
          //       });

          // filteredLinksCard.value = links.value.slice(0, filters.page * perPage);   
          // lastPage.value = Math.ceil(links.value.length / perPage);

          // getlinks();


          //  serverimg.value=process.env.VUE_APP_SERVER_API;
        });


        const getlinks= async () => {

            rodape1.value=[];
            rodape2.value=[];
            rodape3.value=[];
            rodape4.value=[];

            data.cats = data.col_um + "," + data.col_dois + "," + data.col_tres + "," + data.col_quatro 


            isLoading.value = true;
            const apicategoria = await axios.get(`getallcatlinks/${data.cats}`);
            categoriaLinks.value = apicategoria.data;
            isLoading.value = false;

           // console.log(categoriaLinks.value);

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_um)
               {
                 rodape1.value.push(v);
                 data.label_rod1 = v['categoria'];
               }
                 
            });

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_dois)
               {
                 rodape2.value.push(v);
                 data.label_rod2 = v['categoria'];
               }
                 
            });

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_tres)
               {
                 rodape3.value.push(v);
                 data.label_rod3 = v['categoria'];
               }
                 
            });

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_quatro)
               {
                 rodape4.value.push(v);
                 data.label_rod4 = v['categoria'];
               }
                 
            });

            //console.log(rodape1.value);


        }

        const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');


        const filtersChanged = (f:any) => {
            filters.s = f.s;
          //  filters.sort = f.sort;
            filters.page = f.page;

            let linksloc= links.value.filter((p:any)=> (p.short_name ? p.short_name :'').toLowerCase().indexOf(filters.s.toLowerCase()) >= 0 ||
                                                        (p.long_name ? p.long_name : '').toLowerCase().indexOf(filters.s.toLowerCase()) >= 0);


            lastPage.value = Math.ceil(linksloc.length / perPage);
            filteredLinksCard.value = linksloc.slice(0, filters.page * perPage);
        }
      

        return {
          apiData,
          data,
          rodape1,
          rodape2,
          rodape3,
          rodape4,
          isLoading,
          onCancel,
          filters,
          lastPage,
          filtersChanged,
          filteredLinksCard,
          postCardFixo,
          postCard
        }
    }

}
