<template>
    <main class=" d-flex flex-column justify-content-center">
      <form @submit.prevent="submit">
        <div class="d-flex flex-column align-items-center justify-content-center position-relative ">
            <section class="login_form d-flex flex-column align-items-center">
                <!-- <div class="login_form_img">
                    <img src="img/PDenvia.svg" class="card-img-top" alt="PDEnvia mail marketing">
                </div> -->
                <div >
                    <div class="card align-items-center shadow mb-2">                
                        <div class="card-body  mb-2">
                            
                                    <div class="login_form_title d-flex flex-column justify-content-center text-secondary">
                                        <p class="h2 text-center">Login</p>
                                    </div>
                              
                                  <div class="login_forms flex-column justify-content-center">
                                      <input v-model= "dados.email" type="text" class="form-control"  placeholder="Usuario Dominio" name="email">
                                      <input v-model= "dados.password" type="password" class="form-control" placeholder="Senha" name="password">
                                  </div>
                                        
                                    
                                   
                                        <div class="d-flex flex-column align-items-center justify-content-center  ">

                                           <div>  <button class="btn bg-warning btn-lg mb-2" @click="recaptcha">Não sou robô</button> </div>
                                           
                                           <div><button class="btn bg-secondary btn-lg mb-2" type="submit">Entrar</button> </div>
       
                                        </div>         
                                      
                                        <p class="text-center text-danger" v-if="dados.error">      {{ dados.error }} </p>
                        </div>
         
                    </div>
                </div>
            </section>
        </div>
        <div class="login_footer d-flex flex-column justify-content-center d-none d-sm-block">
            <section class="login_footer_img d-flex flex-column align-items-center justify-content-sm-end mt-5">
                <!-- <img src="img/Pro_Saude_logo_white.svg" alt="Pro-Saúde" srcset=""> -->
            </section>
        </div>
        <!-- <div class="login_footer_text">
            <p class="h5 text-center mt-5 ">Powered by HRBA Labs</p>
        </div> -->

    </form>
  </main> 

</template>

<script>
/*
Autor: Patrick Alex Maia Duarte
Co-Autor: Jorge Abude
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Tela de login que utiliza o css criado pelo Jorge. O sistema consegue validar tanto o usuário entrando com apenas o nome de usuário de domínio,
          como também email (no caso para usuário criado localmente). Quando o servidor de dominio está ausente, o sistema faz a validação com a ultima
          senha que o usuário conseguiu entrar no sistema, porém, ele deve informar o email.
*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';

import { useReCaptcha } from 'vue-recaptcha-v3'

export default {
    name: "Login",
    setup() {

        const store = useStore();
        const route = useRoute();

        const router = useRouter();
        
        const dados = reactive({
              email: '',
              password:'',
              username:'',
              foto:'',
              error:''
        });
        const recaptcha_token = ref('');
         onMounted(async () => {
        //     getUser();
          recaptcha_token.value = ''

         });

         
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

        const recaptcha = async () => {
        const carregado =  await recaptchaLoaded()

          if (carregado){

            const token = await executeRecaptcha('login')
            recaptcha_token.value = token
          //  console.log( token )
          }
       
        }

 

        const getUser = async () => {
                    try {
                        const {data} = await axios.get('user');
                     //   console.log( data);
                        await store.dispatch('setUser', data);
                        
                        await router.push('/sistemas');
                        
                    } catch (e) {
                        await store.dispatch('setUser', null);
                    }


        }

        const submit = async () => {

           // recaptcha()
           if (recaptcha_token.value !='')
           {
                await axios.post('login', {
                        email: dados.email,
                        password: dados.password
                }).then(response => {
                    
                        getUser();
                        console.log("conseguiu logar");
                    
                })
                .catch(error => {
                            //console.log( error.response.status);
                            //console.log( error.response.data.error);
                            dados.error =error.response.data.error;
                })
                .finally(() => 
                            setTimeout( () => {
                                dados.error = '';
                                }, 5000)
                );

           }else{
            console.log("falha no login devido a recaptcha");
            dados.error ='Mostre que você não é um robo';
            setTimeout( () => {
                                dados.error = '';
                                }, 2000)
            recaptcha_token.value = ''

           }
            
  
        }

        return {
            dados,
            submit,
            recaptcha
        }

    }
}

</script>

