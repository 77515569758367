import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36f8b058"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-md-12 mb-4 input-group" }
const _hoisted_3 = { class: "row row-cols-2 row-cols-md-6 row-cols-md-8 g-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "card shadow-sm" }
const _hoisted_6 = ["src", "title"]
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "card-text" }
const _hoisted_9 = {
  key: 0,
  class: "d-flex justify-content-center mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        class: "form-control",
        placeholder: "Search",
        onKeyup: _cache[0] || (_cache[0] = ($event: any) => ($setup.search($event.target.value)))
      }, null, 32)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.plinks, (link) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col",
          key: link.id,
          onClick: ($event: any) => ($setup.openurl($setup.origemserv===0 ? `${link.url_interno}`: `${link.url_externo}`))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: `${$setup.serverimg}${link.image}`,
              height: "80",
              title: link.long_name
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(link.short_name), 1)
            ])
          ])
        ], 8, _hoisted_4))
      }), 128))
    ]),
    ($props.filters.page < $props.lastPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "btn btn-success bg-gradient",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.loadMore && $setup.loadMore(...args)))
          }, " Carregar Mais")
        ]))
      : _createCommentVNode("", true)
  ]))
}