
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer cadastro da rota do sistema e configurar o icone de acesso. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {useRoute, useRouter} from "vue-router";
import {computed,reactive,onMounted, ref, SetupContext, watch,} from "vue";
import {useStore} from "vuex";
import axios from 'axios';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "ListaCreate",
  components:  { Loading},
  props:['listaid','userid'],
  emits:['set-click'],

  setup(props:any, context: SetupContext) {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);


    const data = reactive({
      name: '',
      description: '',
      user_id:0,
      mlist_id:0,
      emailstart:1
            
    });

    const janela = ref(false);


    onMounted( () => {

      janela.value = false;

      data.user_id = parseInt(props.userid);
      data.mlist_id = parseInt(props.listaid);
          
    });

    watch(user, () => {

            if(user.value !==null){

                data.user_id = user.value.id;
             }else{
                  router.push('/');
             }
        });

    const fechar = () =>{
      janela.value = false;
      data.name= '',
      data.description= '',
      data.user_id = parseInt(props.userid);
      data.mlist_id = parseInt(props.listaid);
      data.emailstart=1

    }
        


    const submit = async () => {
       isLoading.value = true;
       await axios.post('grupo', data);
       isLoading.value = false;
       janela.value = true;
       context.emit('set-click', {
          hidden:true
        });

    } 

    const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');
    return {
      data,
      isLoading,
      janela,
      onCancel,
      submit,
      fechar
    }
  }
}
