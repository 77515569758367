
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer a configuração do site sem precisar mexer em código. Permite alterar logo,links do rodapé e definir qual categoria
          será utilizada para mostrar links público.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';
import ImageUpload from "@/components/ImageUpload.vue";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "LayoutSite",
  components: {ImageUpload,  Loading},
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const categorias = ref(<any>[]);
    const links = ref(<any>[]);

    const isLoading = ref(false);

    const data = reactive({
      rodape: '',
      nome_sistema: '',
      boasvinda:'',
      carousel:0,
      col_um: 0,
      col_dois: 0,
      col_tres: 0,
      col_quatro:0,
      facebook:'',
      instagram:'',
      logo:'',
      msg:'',
      success:false,
      showcarousel:false,
      error:false,
      serverimg:process.env.VUE_APP_SERVER_API   
    });

    onMounted(async () => {
      

     checkAcesso();

      isLoading.value = true;
      const response = await axios.get(`showlayoutsite/1`);
      isLoading.value = false;
      data.rodape = response.data.rodape;
      data.nome_sistema= response.data.nome_sistema;
      data.boasvinda= response.data.boasvinda;
      data.carousel=response.data.carousel;
      data.showcarousel = response.data.showcarousel == 1 ? true : false;
      data.col_um= response.data.col_um;
      data.col_dois = response.data.col_dois;
      data.col_tres = response.data.col_tres;
      data.col_quatro = response.data.col_quatro;
      data.facebook = response.data.facebook;
      data.instagram = response.data.instagram;
      data.logo = response.data.logo;

       isLoading.value = true;
      const apilink = await axios.get('linksistema');
      links.value = apilink.data;
       isLoading.value = false;

       isLoading.value = true;
      const apicategoria = await axios.get('categorias');
      categorias.value = apicategoria.data;
       isLoading.value = false;


    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }

    const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');

    const checkshowlink= (valor:any) =>{ 
             data.showcarousel= valor==false ? data.showcarousel=true : data.showcarousel=false;  
             console.log(data.showcarousel); 
           }

    const submit = async () => {
      await axios.put(`updatelayoutsite/1`, data);

        data.msg= 'Parâmetros do layout do sistema atualizado';
        data.success = true;
        setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,500
                );


    }

    return {
      data,
      submit,
      links,
      categorias,
      isLoading,
      onCancel,
      checkshowlink
    }
  }
}
