
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Formulário para cadastro de categoria de links

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {useRoute, useRouter} from "vue-router";
import {computed,reactive,onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from 'axios';
import ImageUpload from "@/components/ImageUpload.vue";

export default {
  name: "CategoriaCreate",
  components: {ImageUpload},
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      short_name: '',
      long_name: '',
      note: '',
      user_action: 1
    });


    onMounted(async () => {
      
          checkAcesso();

    });

    const checkAcesso = async () => {

        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.insert !== 1 ? true : false )
                await router.push('/acessonegado');

        }else if (route.params.t=== 'p'){
            
               if (!(user.value.id > 0))
                 await router.push('/acessonegado');

              const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.insert !== 1 ? true : false )
                await router.push('/acessonegado');
        }else {
            await router.push('/acessonegado');
        }
       
    }

    const submit = async () => {
      await axios.post('categorias', data);

       if (route.params.t=== 'f')
          await router.push(`/categorias/${route.params.t}/${route.params.id}/${0}`);
      else if (route.params.t=== 'p'){
        await router.push(`/categorias/${route.params.t}/${route.params.id}/${route.params.perfil}`);
      }

    }

    return {
      data,
      submit
    }
  }
}
