
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer edição do cadastro da função. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';



export default {
  name: "SiteEdit",
  setup() {

    const smtps = ref<any[]>([]);
    const data = reactive({
      name: '',
      description: '',
      user_id:0,
      qtdmails:0, // campo definido para o ciclo de quantos emails enviado ele fazer uma pausa de x segundos determinado no campo qtdseg.
      qtdseg:0,  // marca um intevalo em segundos para não sobrecarregar o processador.
      accountmail:0,
      msg:'',
      success:false,
      error:false,
      cont:0,
            
    });

    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    
    onMounted(async () => {

      checkAcesso();
     // console.log(route.params.listid);
      const response = await axios.get(`lista/${route.params.listid}`);
      
      data.name = response.data.name;
      data.description = response.data.description;
      data.qtdmails = response.data.qtdmails;
      data.qtdseg = response.data.qtdseg;
      data.accountmail = response.data.accountmail;
      data.user_id = user.value.id;

      const response1 = await axios.get(`listasmtps/${user.value.id}`);
      smtps.value = response1.data;




          
    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }


const submit = async () => {
      await axios.put(`lista/${route.params.listid}`, data);
      data.msg= 'Lista atualizado';
      data.success = true;
      setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,1000
                );

}



     const voltar = async () => {
        if (route.params.t=== 'f')
            await router.push(`/listas/${route.params.t}/${route.params.id}/${0}`);
        else if (route.params.t=== 'p'){
          await router.push(`/listas/${route.params.t}/${route.params.id}/${route.params.perfil}`);
        }

      }




    return {
      data,
      smtps,
      submit,
      voltar
    }
  }

}
