
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Listar os comunicados cadastros no sistema

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {Comunicado} from "@/models/comunicado";
import axios from "axios";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name:"ComunicadosTable",
    components:  { Loading},
    setup() {
        const comunicados = ref<Comunicado[]>([]);

        const acesso = reactive({
            btnInsert:false,
            btnAlter:false,
            btnDelete:false,
            
        });

        const filteredTable = ref(<any>[]);
        const dados = reactive({
            totalreg:0,
            serverimg:process.env.VUE_APP_SERVER_API     
        });

        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const store = useStore();
        const userlogin = computed( () => store.state.user);

        const router = useRouter();
        const route = useRoute();

        const isLoading = ref(false);

        onMounted( async () => {

            loadAcessos();
            loadtable();
        });


        const del = async (id: number) => {
                if (confirm('Deseja excluir?')) {
                   
                    await axios.delete(`comunicados/${id}`);
                   

                   loadtable();// filteredTable.value = comunicados.value.filter((p: any) => p.id !== id);
                }
        }

         const loadAcessos = async () => {
          //   console.log(userlogin.value.id);
        

            if (route.params.t=== 'f'){
                isLoading.value = true;
                const resp = await axios.get(`btngetshowuserfunc/${route.params.id}/${userlogin.value.id}`);
                isLoading.value = false;
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;
            }else if (route.params.t=== 'p'){
                isLoading.value = true;
                const resp = await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`);
                isLoading.value = false;
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;

            }else {
                acesso.btnInsert = false;
                acesso.btnAlter = false;
                acesso.btnDelete = false;
            }

        }

        const loadtable =async () => {
            isLoading.value = true;
            const {data} = await axios.get('comunicados');
            comunicados.value = data;
            dados.totalreg = comunicados.value.length;
            isLoading.value = false;
          
           filteredTable.value = comunicados.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(comunicados.value.length / perPage.value);
        }

        const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(comunicados.value.length / perPage.value);
           filteredTable.value = comunicados.value.slice(0, page.value * perPage.value);

      }

        const search = (s: string ) => {

            filteredTable.value = comunicados.value.filter(p=> (p.short_name?p.short_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.long_name?p.long_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0  );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
        }

        const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');


        return {
            comunicados,
            del,
            userlogin,
            acesso,
            filteredTable,
            search,
            lastPage,
            perPage,
            page,
            loadMore,
            dados,
            route,
            onCancel,
            isLoading               
        }
    }

}
