
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Listar os perfils cadastrados no sistema. Possui ações de desabilitar e habilitar, que afetam se o usuário vai ver ou não disponível
           no menu de acessos. Não permit operação de excluir, por questões de segurança.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, SetupContext,ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";

export default {
    name:"BroadLeads",
    props:['broadlead'],
    emits:['set-click'],
    setup(props:any,  context: SetupContext) {
        const broadLeads = ref<any[]>([]);

        const acesso = reactive({
            btnInsert:false,
            btnAlter:false,
            btnDelete:false,
            
        });

        const filteredTable = ref(<any>[]);

        const dados = reactive({
            totalreg:0,     
        });
        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const store = useStore();
        const userlogin = computed( () => store.state.user);

        const router = useRouter();
        const route = useRoute();

        onMounted( async () => {
            // loadAcessos();
            loadtable();
         //   console.log(links.value);
        });


        watch(props, async () => {                  

            loadtable()

        });


        const loadtable =async () => {
          //  console.log('valor de broadlead no props')
          //  console.log(props.broadlead)
            const {data} = await axios.get(`userbroadleads/${props.broadlead}`);
            broadLeads.value = data;
        
            dados.totalreg = broadLeads.value.length;
          //  filteredTable.value = funcoes.value;

           filteredTable.value = broadLeads.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(broadLeads.value.length / perPage.value);

        }

        const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(broadLeads.value.length / perPage.value);
           filteredTable.value = broadLeads.value.slice(0, page.value * perPage.value);

      }

        const search = (s: string) => {

            filteredTable.value = broadLeads.value.filter(
                                                      p=> (p.lead.name?p.lead.name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.lead.email?p.lead.email:'').toLowerCase().indexOf(s.toLowerCase()) >= 0);
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
        }


      const exportCSV = async () => {
            const {data} = await axios.post('broadLeadsexport', {}, {responseType: 'blob'});
            const blob = new Blob([data], {type: 'text/csv'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(data);
            link.download = 'BroadLeads.csv';
            link.click();
        }

        return {
            broadLeads,
            userlogin,
            acesso,
            filteredTable,
            search,
            lastPage,
            perPage,
            page,
            loadMore,
            dados,
            exportCSV,
            route          
        }
    }

}
