
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Listar cadastro de usuários.


*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref, Ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {User} from "@/models/user";
import axios from "axios";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name:"Users",
    components:  { Loading},
    setup() {
        const acesso = reactive({
            btnInsert:false,
            btnAlter:false,
            btnDelete:false,
            
        });
        const dados = reactive({
            totalreg:0,     
        });
        const filteredTable = ref(<any>[]);
        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const users = ref<User[]>([]);
        const store = useStore();
        const userlogin = computed( () => store.state.user);

        const router = useRouter();
        const route = useRoute();

        const isLoading = ref(false);

        onMounted( async () => { 

           loadAcessos();
           loadtable();

        });


        const loadAcessos = async () => {

            if (route.params.t=== 'f'){
                isLoading.value = true;
                const resp = await axios.get(`btngetshowuserfunc/${route.params.id}/${userlogin.value.id}`);
                isLoading.value = false;
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;
            }else if (route.params.t=== 'p'){
                isLoading.value = true;
                const resp = await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`);
                isLoading.value = false;
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;

            }else {
                acesso.btnInsert = false;
                acesso.btnAlter = false;
                acesso.btnDelete = false;
            }

       
            isLoading.value = true;
            const {data} = await axios.get('users');
            isLoading.value = false;
            users.value = data;
        }

        const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(users.value.length / perPage.value);
           filteredTable.value = users.value.slice(0, page.value * perPage.value);

        }

        const search = (s: string ) => {

            filteredTable.value = users.value.filter(p=> (p.first_name?p.first_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.last_name?p.last_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.email?p.email:'').toLowerCase().indexOf(s.toLowerCase()) >= 0
                                                     );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
        }


        const loadtable =async () => {
           isLoading.value = true;
           const {data} = await axios.get('users');
           isLoading.value = false;
           users.value = data;
           dados.totalreg = users.value.length;

           filteredTable.value = users.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(users.value.length / perPage.value);
        }

       const ativar = async (id: number) => {
                if (confirm('Deseja Ativar Usuário?')) {
                    await axios.put(`ativaruser/${id}`);
                    loadtable();
                }
        }

        const inativar = async (id: number) => {
                if (confirm('Deseja Inativar Usuario?')) {
                    await axios.put(`inativaruser/${id}`);
                    loadtable();
                }
        }

        const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');

        const sincusers = async () => {
            isLoading.value = true;
            const respimport = await axios.get('sincusers');      
            isLoading.value = false

             loadtable();
        }

        return {
            users,
            userlogin,
            acesso,
            filteredTable,
            search,
            lastPage,
            perPage,
            page,
            loadMore,
            dados,
            ativar,
            inativar,
            route,  
            isLoading,
            onCancel,
            sincusers   
        }
    }

}
