
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Mostrar funções ou Perfils ativos como menu de opções para o usuário. É chamado dentro do views\dash\Layout.vue

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {reactive,onMounted,computed, ref,SetupContext} from "vue";

import axios from "axios";

export default {
    name:"Menu",
    props: ['closeMenu'],
    emits:['set-clickMenu'],
    setup(props: any,context: SetupContext) {
    const store = useStore();
     const router = useRouter();
     const user = computed( () => store.state.user);

     const apiData = reactive({
      user:'',
      showMFunc: false,
      showMenu:true,
       urlimg: '' 
     });

     const selected = ref(0);
     const fselected = ref(0); 
     const userfuncs = ref([]);
     const userperfilfuncs = ref([]);
     const funcdisp = ref([]);
     const perfilfuncdisp = ref([]);
     const funcprofdisp = ref([]);
     const tempdados = ref([]);

     const funcsprofMenu= ref([]);

      onMounted(async () => {

         if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
            apiData.urlimg = process.env.VUE_APP_SERVER_API_EXTERNA;
          else 
            apiData.urlimg = process.env.VUE_APP_SERVER_API
            
          try {
                const {data} = await axios.get('user');
                await store.dispatch('setUser', data);                        
                } catch (e) {
                await store.dispatch('setUser', null);
          }
  

         loadAcessos();
      });

      const closeMenu = () => {
        apiData.showMenu = false;
        context.emit('set-clickMenu', {
          hidden:apiData.showMenu
        });
      }

      const loadAcessos = async () => {
            apiData.user = store.state.user.id;

            //Monta lista de perfils disponíveis (ativos)
            const resp= await axios.get(`getuserperfilacessos/${apiData.user}`); 
            userperfilfuncs.value = resp.data 
  
            userperfilfuncs.value.forEach((value, index) => {
              if (value['profile_disp_menu']!= null)
              {
                perfilfuncdisp.value.push(value);
              }
            });
            
            
            //Monta lista de funções disponíveis para o login do usuário (Ativos)
            const response = await axios.get(`getuserfuncacessos/${apiData.user}`); 
            userfuncs.value = response.data;  
            //console.log(userfuncs.value)
            userfuncs.value.forEach((value, index) => {
                if(value['funcdisp']!=null)
                   funcdisp.value.push(value);
               
            });

            const compare = (a: any,b: any) =>{
                if (a.short_name < b.short_name)
                  return -1;
                 if (a.short_name > b.short_name)
                 return 1;

                 return 0;

            }

            if (funcdisp.value[0])
               apiData.showMFunc = true;

      }


      
      const fselect = () => {fselected.value = fselected.value === 0 ? 1 : 0;
        //  console.log(fselected.value);
      }

       const select =  (id: number) => {

         let achou = false;
         //console.log(id);
         funcsprofMenu.value = [];
         funcprofdisp.value = [];

         selected.value = selected.value !== id ? id : 0;

        perfilfuncdisp.value.forEach((value, index) => {
              achou = false;

              if (value['profile_disp_menu']['id']=== id){

                    achou=true;
                }

              if (achou)
              {

                  funcprofdisp.value.push(value['profile_disp_menu']['funcoes_disp']);
                
              }
         
        });

          tempdados.value= funcprofdisp.value[0];
          funcprofdisp.value =[];

            tempdados.value.forEach((value, index) => {
                 if (value['funcdisp'] != null)
                 {
                    funcprofdisp.value.push(value);
                 }
            });

       }

       const logout = async () => {
          await axios.post('logout');

           router.push('/');
        }

      
      return {
        user,
        apiData,
        closeMenu,  
        perfilfuncdisp,
        funcprofdisp,
        funcdisp,
        selected,
        select,
        fselected,
        fselect,
        logout

      }
    },

}
