<template>
   <div>
        <h3>Informações do Usuário</h3>
            <form @submit.prevent="infoSubmit">

            <div class="mb-3">
                    <label>Foto</label>
                    <div class="input-group">
                        <input v-model="infoData.foto" class="form-control" name="image">
                        <ImageUploadcp :tipo="2" @uploaded="infoData.foto = $event"/>
                    </div>
            </div>
            <div class="mb-3 ">
                <img :src="`${infoData.serverimg}${infoData.foto}` "  width="200"/>
            </div>
            <div class="mb-3">
                <label>Primeiro Nome </label>
                <input v-model="infoData.first_name" class="form-control" name="first_name" required="true">
            </div>
            
            <div class="mb-3">
                <label>Ultimo Nome </label>
                <input v-model="infoData.last_name"  class="form-control" name="last_name" required="true">
            </div>
   
            <div class="mb-3">
                 <label>Usuario / Email </label>
                <input v-model="infoData.email"  class="form-control" name="email" disabled>
            </div>

            <button class="btn btn-outline-secondary mb-3" > Salvar </button>

        </form>
      <!-- <div v-if="infoData.account_type===0"> -->
      <div>
            <h3 class="mt-4">Alterar Senha</h3>
            <form @submit.prevent="passwordSubmit">
                <div class="mb-3">
                    <label>Senha</label>
                    <input v-model="passwordData.password" type="password" class="form-control" name="password">
                </div>
                
                <div class="mb-3">
                    <label>Confirmar Senha</label>
                    <input v-model="passwordData.password_confirm" type="password" class="form-control" name="password_confirm">
                </div>

                <button class="btn btn-outline-secondary mb-3"> Salvar</button>

                <div class="col-md-12 mb-4" v-if="passwordData.msg">
                    <div v-if="passwordData.success" class="alert alert-success" role="alert">
                        {{ passwordData.msg }}
                    </div>
                    <div v-if="passwordData.error" class="alert alert-danger" role="alert">
                        {{ passwordData.msg }}
                    </div>
                </div> 
             </form>

      </div>

    </div>

</template>

<script>
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Alterar dados do usuário.


*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";
import ImageUploadcp from "@/components/ImageUploadcp.vue";




export default {
    name:"Profile",
      components: {ImageUploadcp },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
       

        const user = computed( () => store.state.user);
        const infoData = reactive ( {
            first_name: user.value.first_name,
            last_name: user.value.last_name,
            email: user.value.email,
            account_type:user.value.account_type,
            foto: user.value.foto,
            serverimg:process.env.VUE_APP_SERVER_API
            
        });

        const passwordData = reactive( {
            password: '',
            password_confirm: '',
            msg:'',
            success:false,
            error:false,
        });



        watch(user, () => {
            if(user.value !==null){
                infoData.first_name = user.value.first_name;
                infoData.last_name = user.value.last_name;
                infoData.email = user.value.email;
                infoData.foto = user.value.foto;
             }else{
                  router.push('/');
             }
        });

        const infoSubmit = async () => {
            const {data} = await axios.put('users/info', infoData);
            // console.log(data);
            // console.log(data.email);
            await store.dispatch('setUser', data);
            
        }

        const passwordSubmit = async () => {
            await axios.put('users/password', passwordData);

            passwordData.msg= 'Senha  alterada';
            passwordData.success = true;
            setTimeout( () => { passwordData.msg= '';
                          passwordData.success=false;
                          passwordData.error=false;     
                         }
                ,1000
                );

        }


        return {
            infoData,
            passwordData,
            infoSubmit,
            passwordSubmit
        }
    }

}
</script>

