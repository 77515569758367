
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer cadastro da rota do sistema e configurar o icone de acesso. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

import {useRoute, useRouter} from "vue-router";
import { computed,reactive,onMounted, ref, SetupContext, watch,nextTick} from "vue";
import {useStore} from "vuex";
import axios from 'axios';


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: "Broadgroup",
  components:  { Loading},
  props:['broad_id','listgroups','broadgroups'],
  emits:['update-broadgroup'],

  setup(props:any, context: SetupContext) {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    //const { toClipboard } = useClipboard()
    const cliptext = ref('')
    
    const isLoading = ref(false);

     const showlinks = ref(false)

    
    const chooselk = ref(0)
    

    const data = reactive({
       id:0,
       group_id:0,
       broad_id:props.broad_id,
       user_id:user.value.id
                  
    });

 


    const janela = ref(false);

    const userbroadgroups = ref<any[]>([]); //links
    const listgroups= ref<any[]>([]); // urls
    
    onMounted( () => {

        janela.value = false;
        userbroadgroups.value = props.broadgroups
    });


  
    watch(props, async () => {

      

      if (props.broad_id != null)
      {

        if (props.broadgroups.length> 0 )
        {
           showlinks.value = true
           userbroadgroups.value = props.broadgroups
        }
        else
           showlinks.value = false      

      }

     listgroups.value = props.listgroups;

    //  console.log('listgroup  do broad group')
    //  console.log(listgroups.value)
     data.user_id = user.value.id

    //  console.log('userbroadgroups da broadgroup')
    //  console.log(userbroadgroups.value)
     

    });


    
    const fechar = () =>{
      janela.value = false;

    }


    const showcond = ref(false);

    const showNewLinkForm = () => {

      showlinks.value = false;
      janela.value = true;

          data.broad_id= props.broad_id
          data.user_id= user.value.id
          // data.group_id=0
          
    }


    const ExcluirLink = async (id:any,tag:any) => {

      if (confirm(`deseja excluir lista/group ?`)) {
            isLoading.value = true;
            await axios.delete(`broadgroup/${id}`);
            isLoading.value = false;
       }
       
       context.emit('update-broadgroup', {
                    hidden:true
       });
    }



    const AddListaGroup = async () => {

                data.user_id = user.value.id
                data.broad_id = props.broad_id
                isLoading.value = true;
                await axios.post('broadgroup', data);
                isLoading.value = false;
                chooselk.value= 0;

                context.emit('update-broadgroup', {
                    hidden:true
                });

              
              data.broad_id=props.broad_id
              data.group_id=0

              janela.value = false
              if (props.broadgroups.length> 0 )
                showlinks.value = true
              else
                showlinks.value = false

             


    }

    const cancelcadgrupo = () => {
      janela.value = false
        if (props.broadgroups.length> 0 )
           showlinks.value = true
        else
           showlinks.value = false
    }
    



    const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');
    return {
      data,
      isLoading,
      janela,
      listgroups,
      userbroadgroups,
      fechar,
      showcond,
      showlinks,
      showNewLinkForm,
      AddListaGroup,
      cancelcadgrupo,
      ExcluirLink,
      chooselk,
      cliptext


    }
  }
}
