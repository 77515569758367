<template>
<div> 
<h2> Criar Novo Usuário</h2>

   <div class="pt-3 pb-2 mb-3 ">
              <a href="javascript:void(0)" class="btn btn-sm btn-outline-secondary" title="Voltar" @click="voltar()">
                <font-awesome-icon icon="arrow-circle-left" />
             </a>
   </div>
   
  <form @submit.prevent="submit">


    <div class="mb-3">
      <label>Primeiro Nome</label>
            <input v-model="dataform.first_name" class="form-control"  placeholder="Primeiro Nome">
    </div>

    <div class="mb-3">
       <label>Último Nome</label>
      <input  v-model="dataform.last_name" class="form-control"  placeholder="Último Nome">
     
    </div>

    <div class="mb-3">
      <label>Email </label>
      <input  v-model="dataform.email" type="email" class="form-control"  placeholder="nome@example.com">
      
    </div>

    <div class="mb-3">
      <label>Senha</label>
      <input v-model="dataform.password" type="password" class="form-control" placeholder="Senha">
      
    </div>

    <div class="mb-3">
      <label>Confirme Senha</label>
      <input v-model="dataform.password_confirm" type="password" class="form-control" placeholder="Confirme Senha">
      
    </div>

        <button class="btn btn-outline-secondary mb-3 ">Salvar</button>
    
  </form>


</div>
</template>

<script>
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Criar cadastro de usuário local do sistema. Os usuários de domínios são importados do servidor de domínio. Ambos ficam na mesma tabela
          de usuários, sendo que é diferenciada pelo campo account_type.


*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import {computed,reactive,onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {
    name: "UserCreate",
    setup(){
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const dataform = reactive({
            first_name:'',
            last_name:'',
            email:'',
            password:'',
            password_confirm:'',
    });
    
    onMounted(async () => {

          checkAcesso();

    });
    //verifica acesso usuário
    const checkAcesso = async () => {

      //       //barra tenativa de burlar o usuário logado
      //  if (route.params.userid != user.value.id)
      //            await router.push('/acessonegado');
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.insert !== 1 ? true : false )
                await router.push('/acessonegado');

        }else if (route.params.t=== 'p'){
            
               if (!(user.value.id > 0))
                 await router.push('/acessonegado');

              const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.insert !== 1 ? true : false )
                await router.push('/acessonegado');
        }else {
            await router.push('/acessonegado');
        }

       
    }

       const submit = async () => {
            await axios.post('register',dataform);

          if (route.params.t=== 'f')
              await router.push(`/users/${route.params.t}/${route.params.id}/${0}`);
          else if (route.params.t=== 'p'){
            await router.push(`/users/${route.params.t}/${route.params.id}/${route.params.perfil}`);
          }

                
       }

      const voltar = async () => {
        if (route.params.t=== 'f')
            await router.push(`/users/${route.params.t}/${route.params.id}/${0}`);
        else if (route.params.t=== 'p'){
          await router.push(`/users/${route.params.t}/${route.params.id}/${route.params.perfil}`);
        }

      }


      return {
        dataform,
        submit,
        voltar
      }
    }

}
</script>

