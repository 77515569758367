
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer edição do cadastro da função. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';


import Tabs from "@/components/Tab/Tabs.vue";
import Tab from "@/components/Tab/Tab.vue";


export default {
  name: "FuncaoEdit",
  components:  { Tabs, Tab,},
  setup() {
    const data = reactive({
      id: '',
      short_name: '',
      long_name: '',
      linkname: '',
      icon:'',
      route: '',
      func_pai: 0,
      note: '',
      msg:'',
      showtableFunc: false,
      success:false,
      error:false,
      achou:false,
      remover:false,
      cont:0,
      insert:[]
      
    });

    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();


    const funclist = ref([]);
    const funcselect= ref([]);
    const funcdata = ref([]);

    const userFuncs = ref([]); 
    const profileFuncs = ref([]); 
    const funcProfiles = ref([]); 
    
    const funcs = ref([]);
    const perfils = ref([]);

    const perfildisp = ref([]);
    const selectedPerfils = ref(<any>[]);
    const excSelPerfils = ref(<any>[]);

    const formData = reactive({
      insert: [] as number[],
      notinsert: [] as number[],
      update: [] as number[],
      notupdate: [] as number[],
      delete: [] as number[],
      notdelete: [] as number[]
    });

    
    onMounted(async () => {

      checkAcesso();
      const response = await axios.get(`funcoes/${route.params.funcid}`);

     // data.id = response.data.id;
      data.short_name = response.data.short_name;
      data.long_name = response.data.long_name;
      data.linkname = response.data.linkname;
      data.icon = response.data.icon;
      data.route = response.data.route;
      data.func_pai = response.data.func_pai;
      data.note = response.data.note;

      getFuncProfiles();
    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }


const submit = async () => {
      await axios.put(`funcoes/${route.params.funcid}`, data);
      data.msg= 'Função atualizado';
      data.success = true;
      setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,1000
                );
     // await router.push('/users');
}

const getFuncProfiles = async () => {

       let achou = false;
       perfildisp.value = [];
        try {
            const resp = await axios.get(`getfuncprofiles/${route.params.funcid}`); 
            funcProfiles.value = resp.data;
            //console.log(funcProfiles.value );
            if (resp.data.length > 0){
              data.showtableFunc=true;
            }else{
              funcProfiles.value = [];
              data.showtableFunc=false;
            }
    
            //Monta lista de perfils disponíveis
            const response = await axios.get('perfils'); 
            perfils.value = response.data;  
            perfils.value.forEach((value, index) => {
              achou = false;
              funcProfiles.value.forEach((v,ind ) => {
                if (value['id']=== v['id']){
                    achou=true;
                }
              })

              if (!achou)
              {
                perfildisp.value.push(value);
              }
            });
              
        } catch (e) {
              funcProfiles.value = [];
              data.showtableFunc=false;
        } 
    }

    //Adicionar funções para usuário
    const storeFuncProfile = async () => {

      try
      {
       // console.log("Chegou na storeUserFunc");
       // console.log(selectedPerfils.value);
        await axios.post( `funcprofiles/${route.params.funcid}`, {perfils:selectedPerfils.value} );
        
        getFuncProfiles();
      }catch (e) {
            data.msg= 'Falha na atualização';
            data.error = true;
      }finally {
      setTimeout( () => { data.msg= ''; 
                          data.success=false;
                          data.error=false;
                        }
                ,1000
                );
      }
    }

const selectIns = (id: number, checked: boolean) => {
  if (checked) {
    formData.insert = [...formData.insert, id];
    //console.log('Insert ' + formData.insert);
     formData.notinsert = formData.notinsert.filter(p => p !== id);
        // console.log('notinsert  ' + formData.notinsert);
    return;
  }else {
    formData.notinsert = [...formData.notinsert, id];
      //console.log('notinsert ' + formData.notinsert);
     formData.insert = formData.insert.filter(p => p !== id);
      //console.log('Insert ' + formData.insert);
    return;
  }

  }

const checkedIns = (id: number) => {
  if(id=== 1){
    return true;
   
  }
  else
  {

    return false;
  }
    
}

const selectUp = (id: number, checked: boolean) => {
  if (checked) {
    formData.update = [...formData.update, id];
     formData.notupdate = formData.notupdate.filter(p => p !== id);
    return;
  }else {
    formData.notupdate = [...formData.notupdate, id];
     formData.update = formData.update.filter(p => p !== id);
    return;
  }
}

const checkedUp = (id: number) => {
  if(id=== 1)
    return true;
  else
    return false;    
}



const selectDel = (id: number, checked: boolean) => {
  if (checked) {
    formData.delete = [...formData.delete, id];
     formData.notdelete = formData.notdelete.filter(p => p !== id);
    return;
  }else {
    formData.notdelete = [...formData.notdelete, id];
     formData.delete = formData.delete.filter(p => p !== id);
    return;
  }
}

const checkedDel = (id: number) => {
  if(id=== 1)
    return true;
  else
    return false;    
}

        //Excluir Funções do Perfil
const storeFuncProfileUpdate =async () => {
    //console.log('Items selecionados para exclusão ' + excSelPerfils.value);
      await axios.put( `funcprofiles/${route.params.funcid}`, {perfils:excSelPerfils.value,
                                                             insert:formData.insert,
                                                             notinsert:formData.notinsert,
                                                             update:formData.update,
                                                             notupdate:formData.notupdate,
                                                             delete:formData.delete,
                                                             notdelete:formData.notdelete });
      excSelPerfils.value = [];

      data.msg= 'Item(s) removido(s)';
      data.remover = true;
      setTimeout( () => { data.msg= '';
                          data.remover=false;
                          data.error=false;     
                         }
                ,1000
                );

      getFuncProfiles();
    }

     const voltar = async () => {
        if (route.params.t=== 'f')
            await router.push(`/funcoes/${route.params.t}/${route.params.id}/${0}`);
        else if (route.params.t=== 'p'){
          await router.push(`/funcoes/${route.params.t}/${route.params.id}/${route.params.perfil}`);
        }

      }




    return {
      data,
      submit,
      userFuncs,
      //profileFuncs,
      funcProfiles,
      perfildisp,
      perfils,
      selectedPerfils,
      excSelPerfils,
      storeFuncProfile,
      storeFuncProfileUpdate,
      checkedIns,
      checkedUp,
      checkedDel,
      selectIns,     
      selectUp,
      selectDel,
      voltar
    }
  }

}
