
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:1
Ano: 2022
Objetivo: Fazer edição do cadastro da função. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

import {computed,reactive,onMounted, ref, SetupContext, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import Broadgroup from "@/views/Funil/Campanha/Broadcast/Broadgroup.vue";

export default {
  name: "BroadcastEdit",
  components:  { Loading,Broadgroup},
  props:['broad_id','listgroups'],
  emits:['set-click'],
  setup(props:any, context: SetupContext) {


    const smtps = ref<any[]>([]);
    const isLoading = ref(false);
    const janela = ref(false);

    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      name: '',
      description: '',
      user_id:0,
      qtdmails:0,
      accountmail:0,
      qtdseg:0,
      broad_id:0
            
    });

    const broadgroups = ref<any[]>([]); 
    const listgroups = ref<any[]>([]);

    onMounted(async () => {
      
     // listgroups.value = props.listgroups;
     data.broad_id = props.broad_id
     
    // console.log(props.broad_id)
     



    });

      watch(props, async () => {
            if(props.broad_id !=null){
                  data.broad_id = parseInt(props.broad_id);
                  isLoading.value = true;
                  const response = await axios.get(`broadcast/${data.broad_id}`);
                  isLoading.value = false;

                  data.name = response.data.name;
                  data.description = response.data.description;
                  data.user_id = response.data.user_id;
                  data.qtdmails = response.data.qtdmails;
                  data.qtdseg = response.data.qtdseg;
                  data.accountmail = response.data.accountmail;

  
                  // console.log(props.broad_id)
                  // console.log(data.broad_id)
                  // console.log(user.value.id)

                  const resp = await axios.get(`userbroadgroups/${data.broad_id}/${data.user_id}`);    
                
                  broadgroups.value = resp.data;

                  const response1 = await axios.get(`listasmtps/${data.user_id}`);
                  smtps.value = response1.data;

             }
              
                 listgroups.value = props.listgroups;

                //updatebroadgroup()
                //  console.log('listgroups do broadcast edit')
                //  console.log(listgroups.value)
      });


      const submit = async () => {
          isLoading.value = true;
          await axios.put(`broadcast/${data.broad_id}`, data);
          isLoading.value = false;

          janela.value = true;

          context.emit('set-click', {
            hidden:true
          });
      }

    const fechar = () =>{
      janela.value = false;
    }

          //Atualiza os links do email após um update
    const updatebroadgroup= async () => {


                const resp = await axios.get(`userbroadgroups/${data.broad_id}/${user.value.id}`);    
                
                broadgroups.value = resp.data;
             //   console.log('broadgroups')
             //   console.log(broadgroups.value)

    }



     const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');


      return {
        data,
        smtps,
        submit,
        fechar,
        janela,
        isLoading,
        onCancel,
        updatebroadgroup,
        broadgroups,
        listgroups
      }
    }


  
  }



