

import {computed,reactive,onMounted,SetupContext, ref,watch} from "vue";
import EditFormGroup from "@/views/Funil/Form/GroupFormEdit.vue";
import NewFormGroup from "@/views/Funil/Form/GroupFormCreate.vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";
import { anyTypeAnnotation } from "@babel/types";

export default {
    name:"GrupoForms",
    components:{EditFormGroup,NewFormGroup},
    props:['grupoforms'],
    emits:['updateGroupFoms'],
    setup(props:any, context: SetupContext) {

        const filteredTable = ref<any[]>([]);

        const dadosForm = ref<any[]>([]);
        const tempForm = ref<any[]>([]);

        const formid = ref()
        const groupid = ref()



        watch(props, () => {

          //  if(filteredTable.value != null)
            if (filteredTable.value != props.grupoforms)
            
                filteredTable.value = props.grupoforms

               // console.log('formulários')
               //  console.log(filteredTable.value )

        })

        const formedit = (group:any, form:any) => {

        //    console.log(group)
        //    console.log(form)

         //   console.log('dadosForm')
        //    console.log(dadosForm.value)

            tempForm.value = []
           // console.log('filteredTable.value')
           // console.log( filteredTable.value)
            filteredTable.value.forEach( (v:any, i:any) =>{
                if ( v['id']==group)
                {
                    tempForm.value.push(v['forms'])
                }
            })

            dadosForm.value = []
            tempForm.value = tempForm.value[0]

           // console.log('form temporário')
           // console.log( tempForm.value)

            tempForm.value.forEach( (v:any,i:any) => {
                if (v['id']==form){
                    dadosForm.value.push(v)
                }
            })

        //    console.log(' depois dadosForm')
        //    console.log(dadosForm.value)
            
          //  console.log('dados do formulário selecionado')
          //  dadosForm.value = dadosForm.value[0]

            formid.value = form
          //  console.log(dadosForm)

        }

        const formnovo = (grupo:any) => {
            groupid.value = grupo
        }

        const triggerForm = async () => {
            context.emit('updateGroupFoms', {
                 hidden:true
            }); 
        }

        const delform = async (id: number) => {
                if (confirm(`Deseja excluir este Formulário ${id}?`)) {
                   
                    const resp = await axios.delete(`grupoform/${id}`);
                    context.emit('updateGroupFoms', {
                        hidden:true
                    }); 
                }
        }

        return {
            filteredTable,
            formedit,
            formnovo,
            delform,
            triggerForm,
            dadosForm,
            formid,
            groupid
        }
    }



}
