
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Cadastrar usuários que devem ser ignorados pela task automática de bloqueio de acesso no ambiente de teste.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';
import ImageUploadcp from "@/components/ImageUploadcp.vue";
import {User} from "@/models/user";

import Tabs from "@/components/Tab/Tabs.vue";
import Tab from "@/components/Tab/Tab.vue";


export default {
  name: "UnlockUserTeste",
  components: {ImageUploadcp,Tabs, Tab},
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      short_name: '',
      long_name: '',
      url_interno: '',
      url_externo: '',
      fornecedor: '',
      image: '',
      user_action: 1,
      msg:'',
      success:false,
      adduserok:false,
      errorAddUser:false,
      error:false,
      achou:false
    });

    const filteredTable = ref(<any>[]);
    const lastPage = ref( 0);
    const perPage = ref(9);
    const page = ref(1);
    const users = ref<User[]>([]);
    const usersGeral = ref<any[]>([]);

    const excSelUsers = ref(<any>[]);
    const usersSel = ref(<any>[]);

    const usersVincLista = ref(<any>[]);
    const tempDados = ref(<any>[]);


    onMounted(async () => {

     checkAcesso();

       loadtable();
       loadUsersLiberados();
    

    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }

    const loadUsersLiberados =async () => {
           
           excSelUsers.value = [];
           usersSel.value = [];

           const {data} = await axios.get( 'tasylockusers');

           usersVincLista.value = data;


      }

      const loadtable =async () => {
           
           const {data} = await axios.get('usuariostasyativos');
          

           users.value = data;
           usersGeral.value = data;
           data.achou = false;
           tempDados.value= [];
           users.value.forEach( (v:any,i:any) => {

             usersVincLista.value.forEach( (value:any,ind:any) => {

                 if(value['nm_usuario']== v['nm_usuario'])
                 {
                   data.achou = true;
                   //colocar a tratativa do achou
                 }
                 
              });

               if (!data.achou)
               {
                 tempDados.value.push(v);
                 
               }

               data.achou = false;
             
           });

           users.value = tempDados.value;

           //dados.totalreg = users.value.length;

           filteredTable.value = users.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(users.value.length / perPage.value);

        loadNome();

      }


     const loadNome = () => {

          let tempDadosVinc = ref(<any>[]);
           
              tempDadosVinc.value = [];

              usersGeral.value.forEach( (v:any,i:any) => {

                      usersVincLista.value.forEach( (value:any,ind:any) => {

                          if(value['nm_usuario']== v['nm_usuario'])
                          {
                            data.achou = true;
                            //colocar a tratativa do achou
                          }
                          
                        });

                        if (data.achou)
                        {
                          tempDadosVinc.value.push(v);
                          
                        }

                        data.achou = false;
                      
                    });

                    usersVincLista.value = tempDadosVinc.value;



     }

      const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(users.value.length / perPage.value);
           filteredTable.value = users.value.slice(0, page.value * perPage.value);

       }

      const storeListaUsers = async () => {
          try
          {
            //await axios.post( `storelinkusers/${route.params.linkid}`, {users:usersSel.value} );

            await axios.post('tasylockusers', {users:usersSel.value} );
            data.msg= 'usuário(s) incluidos na lista para não bloquear';
            data.adduserok=true;
          }catch (e) {
                data.msg= 'Falha na atualização data lista de usuários liberados';
                data.errorAddUser = true;
          }finally {
          setTimeout( () => { data.msg= ''; 
                              data.adduserok=false;
                              data.errorAddUser=false;
                            }
                    ,1000
                    );
          }


           usersSel.value = [];
          loadUsersLiberados();
          loadtable();

      }

      const setListaUsers = async () => {
          try
          {
           // await axios.put( `setlinkusers/${route.params.linkid}`, {users:excSelUsers.value} );
            await axios.put('tasyremoveuserslist', {users:excSelUsers.value} );
            data.msg= 'Lista de usuário(s) atualizado com sucesso';
            data.adduserok=true;
          }catch (e) {
                data.msg= 'Falha na atualização da lista de usuários para não bloquear';
                data.errorAddUser = true;
          }finally {
          setTimeout( () => { data.msg= ''; 
                              data.adduserok=false;
                              data.errorAddUser=false;
                            }
                    ,1000
                    );
          }
          excSelUsers.value = [];
          loadUsersLiberados();
          loadtable();


      }


      const search = (s: string ) => {

            //  console.log('search');
            // console.log(users.value);
            filteredTable.value = users.value.filter((p:any)=> (p.nm_usuario?p.nm_usuario:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                         (p.ds_usuario?p.ds_usuario:'').toLowerCase().indexOf(s.toLowerCase()) >= 0
                                                     );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
      }

    const submit = async () => {
     // await axios.put(`linksistema/${route.params.linkid}`, data);
      await axios.put('tasylockusers', {users:excSelUsers.value} );

        data.msg= 'Lista atualizada';
        data.success = true;
        setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,500
                );

     // await router.push('/links');
    }

    // const voltar = async () => {
    //    if (route.params.t=== 'f')
    //       await router.push(`/links/${route.params.t}/${route.params.id}/${0}`);
    //   else if (route.params.t=== 'p'){
    //     await router.push(`/links/${route.params.t}/${route.params.id}/${route.params.perfil}`);
    //   }

    // }

    return {
      data,
      submit,
      filteredTable,
      page,
      lastPage,
      search,
      loadMore,
      excSelUsers,
      usersSel,
      storeListaUsers,
      usersVincLista,
      setListaUsers

    }
  }
}


