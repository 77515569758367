
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:1
Ano: 2022
Objetivo: Painel de gerenciamento do Robo que vai fazer os disparaos dos emails.

*/

import {computed,reactive,onMounted, ref,watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";

import NovoGrupo from "@/views/Funil/Grupo/GrupoCreate.vue";
import EditGrupo from "@/views/Funil/Grupo/GrupoEdit.vue";
import NovoEmail from "@/views/Funil/Email/EmailCreate.vue";
import EditEmail from "@/views/Funil/Email/EmailEdit.vue";
import GrupoForms from "@/views/Funil/Form/GroupForms.vue";
import VerAgendaEmail from "@/views/Funil/Email/VerAgendaEmail.vue";
import GrupoLeads from "@/views/Funil/Grupo/Leads/GrupoLeads.vue";
import { anyTypeAnnotation } from "@babel/types";

export default {
    name:"ListaGrupos",
    components:{NovoGrupo,EditGrupo,NovoEmail, EditEmail,GrupoForms,VerAgendaEmail, GrupoLeads},
    setup() {
       
        const grupos = ref<any[]>([]);

        const acesso = reactive({
            btnInsert:false,
            btnAlter:false,
            btnDelete:false,
            
        });
        
        const filteredTable = ref(<any>[]);

        const ListaGrupoForms = ref(<any>[]);
        const emailsag = ref(<any>[]);
        
        const grouplead = ref(0);
        const listas = ref<any[]>([]);
        const choosegrupo = ref(0);
        const choosemail = ref<any[]>([]);
        const groupmails = ref(<any[]>([]));
        const urls = ref(<any[]>([]));
        const newposition = ref(0);
       
        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const store = useStore();
        const userlogin = computed( () => store.state.user);

        const dados = reactive({
            user_id:userlogin.value.id,
            showmenu:true,
            totalreg:0,
            robo:''     
        });

        const showmenu = ref(true);
        const router = useRouter();
        const route = useRoute();
        const menu = ref('');

        const carregou = ref(0)


        onMounted( async () => {

            loadAcessos();
            loadtable();
            loadLista();
            menu.value = 'Sequencia Mail';
           
             
        });


        watch(userlogin, () => {
            if(userlogin.value !==null){
                dados.user_id = userlogin.value.id;

                if(carregou.value === 0)
                {
                 //   console.log(carregou.value)
                    loadtable();
                    loadLista();
                    menu.value = 'Sequencia Mail';
                    carregou.value = 1
                    
                }

                     


             }else{
                  router.push('/');
             }
        });



        const changbreadmenu = (texto:string) => {
            menu.value = texto;
        }

        const close = (valor:boolean) =>
        {
            if(valor)
            {
                loadtable();

            }
        } 


        const del = async (id: number) => {
                if (confirm(`Deseja excluir este Grupo ${id}?`)) {
                   
                    const resp = await axios.delete(`grupo/${id}`);
                   // console.log(resp.data)
                   loadtable();
                }
        }

        const loadAcessos = async () => {

            if (route.params.t=== 'f'){
                const resp = await axios.get(`btngetshowuserfunc/${route.params.id}/${dados.user_id}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;
            }else if (route.params.t=== 'p'){

                const resp = await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;

            }else {
                acesso.btnInsert = false;
                acesso.btnAlter = false;
                acesso.btnDelete = false;
            }

        }

        const loadForms = async () => {
         //   console.log('dados.user_id')
        //    console.log(dados.user_id)
            const {data} = await axios.get(`grupoform/${route.params.listid}/${dados.user_id}`);   
            ListaGrupoForms.value = data;

        //    console.log('forms')
        //    console.log(ListaGrupoForms.value)
        }

        const loadtable =async () => {
    
            const {data} = await axios.get(`grupomail/${route.params.listid}/${dados.user_id}`);                     
            grupos.value = data;
            
            loadUrls()
            // console.log('Grupo')
            // console.log(grupos.value);
            dados.totalreg = grupos.value.length;
          

           filteredTable.value = grupos.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(grupos.value.length / perPage.value);

        //    const resp = await axios.get(`grupomail/${route.params.listid}/${dados.user_id}`);                     
        //    grupos.value = resp.data;


           loadForms()
        }


    const loadLista=async () => {

    const {data} = await axios.get(`listatable/${dados.user_id}`); // Para mostrar somente listas pertencente ao usuário
          listas.value = data;
          
          listas.value.forEach((v, ind) => {

            if(v['id']==route.params.listid)
            {
                dados.robo = v['name'];
            }
          })

        }


    const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(grupos.value.length / perPage.value);
           filteredTable.value = grupos.value.slice(0, page.value * perPage.value);
        

      }

    const search = (s: string ) => {
            
            filteredTable.value = grupos.value.filter(p=> (p.name?p.name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.description?p.description:'').toLowerCase().indexOf(s.toLowerCase()) >= 0  );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
      }


        const ativar = async (id: number) => {
                if (confirm('Deseja Ativar essa grupo?')) {
                    await axios.put(`ativargrupo/${id}`);
                    loadtable();
                }
        }

        const inativar = async (id: number) => {
                if (confirm('Deseja Inativar grupo?')) {
                    await axios.put(`inativargrupo/${id}`);
                    loadtable();
                }
        }
        const ativarInativarEmail = async(status:boolean, id: number) =>{

            if (status)
            {
                if (confirm('Deseja Inativar essa email?')) {
                    await axios.put(`inativaremail/${id}`);
                    loadtable();
                }

            }else
            {
                if (confirm('Deseja Ativar essa email?')) {
                    await axios.put(`ativaremail/${id}`);
                    loadtable();
                }
            }

        }

        const Alterar = (id: number) => {
            //console.log(id)
            choosegrupo.value = id;
        }

       const emailedit = async (grupo:any,email:any) => {
            groupmails.value = []
            choosemail.value = []
            //Busca a lista de emails para montar o combobox e pega os dados do email que quer editar. Tudo sem precisar fazer requisição
            grupos.value.forEach( (v:any, i:any) => {
                if (v['id']==grupo)
                {                     
                        groupmails.value.push(v['emails_order'])
                }
            })
            groupmails.value= groupmails.value[0]

            groupmails.value.forEach( (v:any, i:any)=> {
                if (v['id']==email)
                {
                   choosemail.value.push(v)  
                }
            })

            choosemail.value = choosemail.value[0]

            // const respmail= await axios.get(`email/${email}`); //dados pode ser buscado da própria variável que tem o email                     
            // choosemail.value = respmail.data

        }


       const emailnovo= async (id: any) => {
            choosegrupo.value = id;
            grupos.value.forEach((v:any,i:any) => {
             if(v['id']==id)
             {
                newposition.value = v['emails_order'].length;
             }
          });

        }

        const veragendaemail= async (id: any) => {
            choosegrupo.value = id;
           // console.log(id);
           // console.log(userlogin.value.id)
          //  console.log("busca emails agendados")
            const {data} = await axios.get(`emailsag/${id}/${userlogin.value.id}`);

            
            emailsag.value = data.emails;
           // console.log("emails agendados")
          //  console.log(emailsag.value);

        }

       

       const delEmail = async (id: number) => {
                if (confirm(`Deseja excluir este Email ${id}?`)) {
                   
                    const resp = await axios.delete(`email/${id}`);
                   loadtable();
                }
        }

        const showleads = (id: number) => {
            
            grouplead.value = id
           // console.log('valor do grupo lead')
           // console.log(grouplead.value )
        }

        

       const emailteste = async (id: number,title: string) => {
                if (confirm(`Deseja enviar Email Teste <stronger>${title} </stronger>`)) {
                   
                    const resp = await axios.get(`emailteste/${userlogin.value.id}/${id}`);
                    
                }
        }

       const loadUrls = async () => {

            const {data} = await axios.get(`userlinks/${dados.user_id}`);

            urls.value = data
       }

        return {
            del,
            showmenu,
            close,
            menu,
            changbreadmenu,
            ativar,
            listas,
            inativar,
            userlogin,
            acesso,
            filteredTable,
            search,
            lastPage,
            perPage,
            page,
            loadMore,
            dados,
            route,
            Alterar,
            choosegrupo,
            newposition,
            emailedit,
            emailnovo,
            delEmail,
            choosemail,
            groupmails,
            ativarInativarEmail,
            urls,
            ListaGrupoForms,
            loadForms,
            veragendaemail,
            emailsag,
            emailteste,
            showleads,
            grouplead 
            
                  
        }
    }

}
