
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer edição dos links do sistema

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';
import ImageUploadcp from "@/components/ImageUploadcp.vue";
import {User} from "@/models/user";

import Tabs from "@/components/Tab/Tabs.vue";
import Tab from "@/components/Tab/Tab.vue";


export default {
  name: "LinkEdit",
  components: {ImageUploadcp,Tabs, Tab},
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      short_name: '',
      long_name: '',
      url_interno: '',
      url_externo: '',
      fornecedor: '',
      image: '',
      user_action: 1,
      msg:'',
      success:false,
      adduserok:false,
      errorAddUser:false,
      error:false,
      achou:false
    });

    const filteredTable = ref(<any>[]);
    const lastPage = ref( 0);
    const perPage = ref(9);
    const page = ref(1);
    const users = ref<User[]>([]);

    // const selectedFuncs = ref(<any>[]);
    const excSelUsers = ref(<any>[]);
    const usersSel = ref(<any>[]);

    const usersVincLink = ref(<any>[]);
    const tempDados = ref(<any>[]);


    onMounted(async () => {

     checkAcesso();

      const response = await axios.get(`linksistema/${route.params.linkid}`);

      data.short_name = response.data.short_name;
      data.long_name= response.data.long_name;
      data.url_interno= response.data.url_interno;
      data.url_externo= response.data.url_externo;
      data.fornecedor= response.data.fornecedor;
      data.image = response.data.image;

       loadLinkUsersAssociados();
      loadtable();

    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }

    const loadLinkUsersAssociados =async () => {
           //isLoading.value = true;
           excSelUsers.value = [];
           usersSel.value = [];
          // console.log(route.params.linkid);
           const {data} = await axios.get( `getlinkusers/${route.params.linkid}`);
              
           usersVincLink.value = data;
          //  console.log('aqui dentro da função');
          //  console.log(usersVincLink.value);

      }

      const loadtable =async () => {
           //isLoading.value = true;
           const {data} = await axios.get('users');
          // isLoading.value = false;

          

           users.value = data;

           data.achou = false;
           tempDados.value= [];
           users.value.forEach( (v:any,i:any) => {

               usersVincLink.value.forEach( (value:any,ind:any) => {

                 if(value['id']== v['id'])
                 {
                   data.achou = true;
                   //colocar a tratativa do achou
                 }
                 
              });

               if (!data.achou)
               {
                 tempDados.value.push(v);
                 
               }

               data.achou = false;
             
           });

           users.value = tempDados.value;

           //dados.totalreg = users.value.length;

           filteredTable.value = users.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(users.value.length / perPage.value);


      }




      const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(users.value.length / perPage.value);
           filteredTable.value = users.value.slice(0, page.value * perPage.value);

       }

      const storeLinkUsers = async () => {
          try
          {
            await axios.post( `storelinkusers/${route.params.linkid}`, {users:usersSel.value} );
            data.msg= 'Link e usuário(s) atualizado com sucesso';
            data.adduserok=true;
          }catch (e) {
                data.msg= 'Falha na atualização do link quanto ao vinculo dos usuários';
                data.errorAddUser = true;
          }finally {
          setTimeout( () => { data.msg= ''; 
                              data.adduserok=false;
                              data.errorAddUser=false;
                            }
                    ,1000
                    );
          }


           usersSel.value = [];
          loadLinkUsersAssociados();
          loadtable();

      }

      const setLinkUsers = async () => {
          try
          {
            await axios.put( `setlinkusers/${route.params.linkid}`, {users:excSelUsers.value} );
            data.msg= 'Link e usuário(s) atualizado com sucesso';
            data.adduserok=true;
          }catch (e) {
                data.msg= 'Falha na atualização do link quanto ao vinculo dos usuários';
                data.errorAddUser = true;
          }finally {
          setTimeout( () => { data.msg= ''; 
                              data.adduserok=false;
                              data.errorAddUser=false;
                            }
                    ,1000
                    );
          }
          excSelUsers.value = [];
          loadLinkUsersAssociados();
          loadtable();


      }


      const search = (s: string ) => {

            filteredTable.value = users.value.filter(p=> (p.first_name?p.first_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.last_name?p.last_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.email?p.email:'').toLowerCase().indexOf(s.toLowerCase()) >= 0
                                                     );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
      }

    const submit = async () => {
      await axios.put(`linksistema/${route.params.linkid}`, data);

        data.msg= 'Link atualizado';
        data.success = true;
        setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,500
                );

     // await router.push('/links');
    }

    const voltar = async () => {
       if (route.params.t=== 'f')
          await router.push(`/links/${route.params.t}/${route.params.id}/${0}`);
      else if (route.params.t=== 'p'){
        await router.push(`/links/${route.params.t}/${route.params.id}/${route.params.perfil}`);
      }

    }

    return {
      data,
      submit,
      filteredTable,
      page,
      lastPage,
      search,
      loadMore,
      excSelUsers,
      usersSel,
      storeLinkUsers,
      usersVincLink,
      setLinkUsers,
      voltar

    }
  }
}


