

import {computed,reactive,onMounted,SetupContext, ref,watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name:"GroupFormEdit",
    components:  { Loading},
    props:['dadosForm','formid'],
    emits:['updateGroupForm'],

    setup(props:any, context: SetupContext) {


    const data = reactive({
      name: '',
      description: '',
      user_id:0,
      group_id:0,
      url_thankyou:'',
      url_fail:'',
      url_fail_resend:'',
      code:'',
      coduser:''            
    });

    const html = ref()
    const filteredTable = ref<any[]>([]);
    const janela = ref(false);
    const isLoading = ref(false);

    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();


    watch(props, () => {

         // if(props.formid !==null){
         //   console.log('passado dos parametros')
         //   console.log(props.dadosForm)
            data.name = props.dadosForm[0].name
            data.description = props.dadosForm[0].description
            data.url_thankyou = props.dadosForm[0].url_thankyou
            data.code = props.dadosForm[0].code
            data.url_fail = props.dadosForm[0].url_fail
            data.url_fail_resend = props.dadosForm[0].url_fail_resend
            data.group_id = props.dadosForm[0].group_id
            data.user_id = props.dadosForm[0].user_id

          //  console.log('user')
         //   console.log(user)
          //  data.codeuser = user.value.codeuser
            html.value = `<form action="${process.env.VUE_APP_SERVER_API}a/f/${user.value.coduser}/${data.code}" accept-charset="UTF-8" method="post">`

            html.value += `<div class="mb-3">`
            html.value +=`<label>Cadastre seu melhor email  para receber com exclusividade novos artigos</label>`
            html.value += `</div>`

            html.value += `<div class="mb-3">`
            html.value += `<input type="text" class="form-control" placeholder="Seu Nome" name="name">`
            html.value += `</div>`

            html.value += `<div class="mb-3">`
            html.value += `<input type="email" class="form-control" placeholder="name@example.com " name="email">`
            html.value += `</div>`

            html.value += `<div class="mb-3">`
            html.value += `<button class="btn btn-primary" type="submit">Cadastrar</button>`
            html.value += `</div>`

            html.value += `<p> <small class="text-muted">Seus dados estão seguros, claro. </small><br> </p>`


            html.value += `</form>`
                //  context.emit('updateGroupFoms', {
                //     hidden:true
                //   }); 
        //  } 
    })

    const fechar = () =>{
      janela.value = false;
    }

    const submit = async () => {
          isLoading.value = true;
          await axios.put(`grupoform/${props.formid}`, data);
          isLoading.value = false;

          janela.value = true;

          context.emit('updateGroupForm', {
            hidden:true
          });
      }

      const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');

        return {
            data,
            janela,
            fechar,
            isLoading,
            onCancel,
            submit,
            html 
        }
    }



}
