<template>
<div class="container">
  
   <div class="pt-3 pb-2 mb-3 ">
      <input class="form-control" placeholder="Digite aqui" @keyup="search($event.target.value)">
  </div>

  <Carousel :autoplay="5000" :itemsToShow="6.95" :wrapAround="true">
        <Slide v-for="slide in filteredTable" :key="slide.id"  >
            <div class="carousel__item">
              
                
              <div class="col"  @click="openurl(slide.url_interno)" :title="slide.long_name" >
                <img class="img-fluid mr-5" :src="`${serverimg}${slide.image}`" >
                 
              </div>
            
            </div>

        </Slide>

        <template #addons="{ slidesCount }">
          <Navigation v-if="total > 1" />
             
        </template>

  </Carousel>
</div>
</template>

<script>
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Acionado para  mostrar os links em forma de slideshow. Porém, não está em uso, mas serve como exemplo.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive, onMounted, ref} from "vue";

import axios from "axios";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
    name:"Link",
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const slides = ref([]);
        const total = ref(0);
        const filteredTable = ref([]);

        const serverimg = ref('');

        const cat_pub = ref(0);

        // const categoriaLinks = ref(<any>[]);

        const categoriaLinks = ref([]);
        
 
        onMounted(async () => {


               slides.value = [];
               // const resp = await axios.get('showlinks'); 
                const resp = await axios.get(`showlayoutsite/1`);
                cat_pub.value= resp.data.carousel;
                // data.showcarousel = resp.data.showcarousel == 1 ? true : false;

                const apicategoria = await axios.get(`getallcatlinks/${cat_pub.value}`);
                categoriaLinks.value = apicategoria.data;

               

                categoriaLinks.value.forEach( (v, i) => {
              
                    if (v['cat_id']==cat_pub.value)
                    {
                      slides.value.push(v);
                    }
                 
                });
                // console.log('Dados do slide aqui');
                // console.log(slides.value);
               
                total.value = slides.value.length;
                filteredTable.value = slides.value;
                // serverimg.value = process.env.VUE_APP_SERVER_API;

                if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
                  serverimg.value = process.env.VUE_APP_SERVER_API_EXTERNA;
                else 
                  serverimg.value = process.env.VUE_APP_SERVER_API;


              //https://ismail9k.github.io/vue3-carousel/getting-started.html#available-props   (Funcionou)

        });

       const search = (s) => {

            filteredTable.value = slides.value.filter((p)=> (p.short_name?p.short_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.long_name?p.long_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0  );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
        }

      const openurl = (url) => {
         window.open(url, '_blank');
      }


        return {
          slides,
          total,
          filteredTable,
          search,
          openurl,
          lastPage,
          perPage,
          page,
          serverimg
            
        }
    }

}
</script>

<style scoped>
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}

.carousel-item .card img
{

    background-size: cover !important;   
    background-position: center !important;
}
</style>