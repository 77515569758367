

import {computed,reactive,onMounted,SetupContext, ref,watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from "axios";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name:"GroupFormEdit",
    components:{ Loading},
    props:['groupid'],
    emits:['updateGroupForm'],

    setup(props:any, context: SetupContext) {


    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      name: '',
      description: '',
      user_id:user.value.id,
      group_id:props.groupid,
      url_thankyou:'',
      url_fail:'',
      url_fail_resend:''
            
    });


    const filteredTable = ref<any[]>([]);
    const janela = ref(false);
    const isLoading = ref(false);


    watch(props, () => {

            data.group_id = props.groupid
            data.user_id = user.value.id

    })


    const fechar = () =>{
      janela.value = false;
      data.name= ''
      data.description= ''
      data.url_thankyou = ''
      data.url_fail = ''
      data.url_fail_resend = ''
      data.user_id = user.value.id
      data.group_id = parseInt(props.groupid);

    }

    const submit = async () => {
          isLoading.value = true;
          await axios.post(`grupoform`, data);
          isLoading.value = false;

          janela.value = true;

          context.emit('updateGroupForm', {
            hidden:true
          });
      }

      const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');

        return {
            data,
            janela,
            fechar,
            isLoading,
            onCancel,
            submit 
        }
    }



}
