<template>
  <div class="tab" v-if="isActive">
    <slot></slot>
  </div>
</template>

<script>
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Nota: Componente terceiro. Recomendado não alterar, visto que, pode impactar na tab de várias telas de cadastro.

*/

import { onBeforeMount, ref, inject, watch } from "vue";

export default {
  name: "Tab",
  props: ["title"],
  setup(props) {
    const isActive = ref(false);
    const tabs = inject("TabsProvider");

    watch(
      () => tabs.selectedIndex,
      () => {
        isActive.value = props.title === tabs.selectedIndex;
      }
    );

    onBeforeMount(() => {
      isActive.value = props.title === tabs.selectedIndex;
    });
    return { isActive };
  },
};
</script>