
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:
Ano: 2022
Objetivo: Fazer edição dos links do sistema

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';





export default {
  name: "NotificationCreate",
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      title: '',
      mensagem: '',
      descricao: '',
      tag: '',
      url: '',
      imglogo:'',
      obs:'',
      code:'',
      // interval:'',
      // dayfixed:'',
      group_id:1,
      user_id:user.value.id,
      site_id: route.params.siteid,
      msg:'',
      success:false,
      adduserok:false,
      errorAddUser:false,
      error:false,
      achou:false
    });

    onMounted(async () => {

     checkAcesso();
     
    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }

    const submit = async () => {
      await axios.post(`notification`, data);

      if (route.params.t=== 'f')
          await router.push(`/sitenotification/${route.params.t}/${route.params.id}/${0}/${route.params.siteid}`);
      else if (route.params.t=== 'p'){
        await router.push(`/sitenotification/${route.params.t}/${route.params.id}/${route.params.perfil}/${route.params.siteid}`);
      }
    }

    const voltar = async () => {
       if (route.params.t=== 'f')
          await router.push(`/sitenotification/${route.params.t}/${route.params.id}/${0}/${route.params.siteid}`);
      else if (route.params.t=== 'p'){
        await router.push(`/sitenotification/${route.params.t}/${route.params.id}/${route.params.perfil}/${route.params.siteid}`);
      }

    }

    return {
      data,
      submit,
      voltar

    }
  }
}


