<template>
       
        <Carousel :autoplay="9000" :wrap-around="true" >
        <Slide v-for="slide in slides" :key="slide.id"  >
           <div class="carousel__item">
               <a :href="`${slide.url}`" target="_blank" class= "d-flex align-items-center text-dark " @click="openUrlImg">
                  <img class="img-fluid" :src="`${serverimg}${slide.image}`">
                </a>
           </div>
        </Slide>

        <template #addons="{ slidesCount }">
            <pagination />
        </template>
      </Carousel>
  

</template>

<script >
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Mostrar os comunicados cadastrados na função comunicados. É chamado dentro da views\dash\Layout.vue

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */

import {computed,reactive, onMounted, ref,SetupContext} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import {useRoute} from "vue-router";


import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
    name:"Comunicado",
     emits:['set-clickCom'],
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    setup(props,context) {
        const store = useStore();
        const route = useRoute();

        const slides = ref([]);
        const total = ref(0);
        const serverimg = ref('');
        
 
        onMounted(async () => {

                if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
                  serverimg.value = process.env.VUE_APP_SERVER_API_EXTERNA;
                else 
                  serverimg.value = process.env.VUE_APP_SERVER_API;

                const resp = await axios.get('externalcomunicados');
                slides.value = resp.data;
                total.value = slides.value.length;

        });

        const openUrlImg = () => {
          context.emit('set-clickCom', {
            open:true
          });
        }


        return {
          slides,
          total,
          serverimg,
          openUrlImg
            
        }
    }

}
</script>


