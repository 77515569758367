

/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Editar os dados do usuário, atribuindo permissões a links, perfils e alteração de senha. 
          obs: A alteração de senha só se aplica para usuário local.


*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */

import axios from 'axios';

import {computed,reactive,onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import Tabs from "@/components/Tab/Tabs.vue";
import Tab from "@/components/Tab/Tab.vue";


export default {
  name: "UserEdit",
  components:  { Tabs, Tab,},
  setup() {
    const data = reactive({
      first_name: '',
      last_name: '',
      email: '',
      msg:'',
      showtableProfile: false,
      showtableFunc: false,
      success:false,
      error:false,
      achou:false,
      cont:0,
      insert:[],
      
    });

    const passwordData = reactive( {
            password: '',
            password_confirm: ''
      });
   
    
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();


    const userPerfils = ref([]);
    const profiles = ref([]);
    const profdisp = ref([]);
    const selectedProfiles = ref(<any>[]);
    const excSelProfiles = ref(<any>[]);

    const funclist = ref([]);
    const funcselect= ref([]);
    const funcdata = ref([]);

    const userFuncs = ref([]); 
    const funcs = ref([]);
    const selectedFuncs = ref(<any>[]);
    const excSelFuncs = ref(<any>[]);
    const funcdisp = ref([]);
    const userQuota = ref([]);
    //const funcinsert = ref(<any>[]);
    //const funcupdate = ref(<any>[]);
    //const funcdelete = ref(<any>[]);

    const formData = reactive({
      insert: [] as number[],
      notinsert: [] as number[],
      update: [] as number[],
      notupdate: [] as number[],
      delete: [] as number[],
      notdelete: [] as number[]
    });

    const quotaData = reactive( {
            id:0,
            qtd_mail:0,
            qtd_send_mail:0,
            qtd_send_mail_fail:0,
            status:0
      });

    
    onMounted(async () => {

      checkAcesso();
      const response = await axios.get(`users/${route.params.userid}`);

      data.first_name = response.data.first_name;
      data.last_name = response.data.last_name;
      data.email = response.data.email;

      getUserProfiles()
      getUserFuncs()
      getUseQuotas()


    });



    const passwordSubmit = async () => {
            await axios.put(`users/resetsenha/${route.params.userid}`, passwordData);
           
             passwordData.password='';
             passwordData.password_confirm='';         
            data.msg= 'Senha resetada';
            data.success = true;
      setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,1000
                );

    }

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }

       
    }

//https://stackoverflow.com/questions/11922383/how-can-i-access-and-process-nested-objects-arrays-or-json  (tudo sobre map)
//https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf


//######################################################################################################################
    const getUserProfiles = async () => {
       let achou = false;
       profdisp.value = [];
        try {
            const resp = await axios.get(`getuserprofiles/${route.params.userid}`); 
            userPerfils.value = resp.data;
            if (resp.data.length > 0){
              data.showtableProfile=true;
            }else{
              data.showtableProfile=false;
            }
    
            //Monta lista de perfils disponíveis
            const response = await axios.get('profiles'); 
            profiles.value = response.data;  
            profiles.value.forEach((value, index) => {
              achou = false;
              userPerfils.value.forEach((v,ind ) => {
                if (value['id']=== v['id']){
                    achou=true;
                }
              })

              if (!achou)
              {
                profdisp.value.push(value);
              }
            });
              
        } catch (e) {
              userPerfils.value = [];
              data.showtableProfile=false;
        } 
    }
    //Excluir profiles do usuário
    const storeUserProfUpdate =async () => {
      await axios.put( `userprofiles/${route.params.userid}`, {perfils:excSelProfiles.value});
      excSelProfiles.value = [];
      getUserProfiles();

      data.msg= 'Permissões atualizadas';
      data.success = true;
      setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,500
       );
    }


    const submit = async () => {
      await axios.put(`users/${route.params.userid}`, data);
      data.msg= 'Cadastro atualizado';
      data.success = true;
      setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,500
                );
     // await router.push('/users');
    }

    const storeUserProfile = async () => {

      try
      {
        await axios.post( `userprofiles/${route.params.userid}`, {perfils:selectedProfiles.value} );
        getUserProfiles();

      }catch (e) {
            data.msg= 'Falha na atualização';
            data.error = true;
      }finally {
      setTimeout( () => { data.msg= ''; 
                          data.success=false;
                          data.error=false;
                        }
                ,500
                );
      }
      //await router.push('/users');
    }

    //######################################################################################################################

const getUseQuotas = async () => {
  try{

    const resp = await axios.get(`getuserquota/${route.params.userid}`);
 //   userQuota.value = resp.data;
    quotaData.id = resp.data[0].id
    quotaData.qtd_mail = resp.data[0].qtd_mail
    quotaData.qtd_send_mail = resp.data[0].qtd_send_mail
    quotaData.qtd_send_mail_fail = resp.data[0].qtd_send_mail_fail
    quotaData.status = resp.data[0].status
    
    
   // console.log(resp.data)
//    console.log(quotaData.status)
  }catch (e) {
    console.log(e)
  }
}



const getUserFuncs = async () => {

       let achou = false;
       funcdisp.value = [];
        try {
            const resp = await axios.get(`getuserfuncs/${route.params.userid}`); 
            userFuncs.value = resp.data;
           // console.log(userFuncs.value );
            if (resp.data.length > 0){
              data.showtableFunc=true;
            }else{
              userFuncs.value = [];
              data.showtableFunc=false;
            }
    
            //Monta lista de perfils disponíveis
            const response = await axios.get('funcs'); 
            funcs.value = response.data;  
            funcs.value.forEach((value, index) => {
              achou = false;
              userFuncs.value.forEach((v,ind ) => {
                if (value['id']=== v['id']){
                    achou=true;
                }
              })

              if (!achou)
              {
                funcdisp.value.push(value);
              }
            });
              
        } catch (e) {
              userFuncs.value = [];
              data.showtableFunc=false;
        } 
    }

    //Adicionar funções para usuário
    const storeUserFunc = async () => {

      try
      {
       // console.log("Chegou na storeUserFunc");
       // console.log(selectedFuncs.value);
        await axios.post( `userfuncs/${route.params.userid}`, {funcs:selectedFuncs.value} );
        getUserFuncs();
        
      }catch (e) {
            data.msg= 'Falha na atualização';
            data.error = true;
      }finally {
      setTimeout( () => { data.msg= ''; 
                          data.success=false;
                          data.error=false;
                        }
                ,1000
                );
      }
    }

    const storeUserQuota = async () =>{
      try{
        if(quotaData.qtd_mail <= quotaData.qtd_send_mail){
            data.msg= 'Quota de Email inferior ao já consumido';
            data.error = true;
        }
        await axios.put( `userquota/${quotaData.id}`, {quotaData} );
        data.msg= 'Quota atualizada com sucesso!';
        data.success = true;
      }catch (e) {
            data.msg= 'Falha na atualização';
            data.error = true;
      }finally {
      setTimeout( () => { data.msg= ''; 
                          data.success=false;
                          data.error=false;
                        }
                ,1000
                );
      }
    }

const selectIns = (id: number, checked: boolean) => {
  if (checked) {
    formData.insert = [...formData.insert, id];
    //console.log('Insert ' + formData.insert);
     formData.notinsert = formData.notinsert.filter(p => p !== id);
        // console.log('notinsert  ' + formData.notinsert);
    return;
  }else {
    formData.notinsert = [...formData.notinsert, id];
      //console.log('notinsert ' + formData.notinsert);
     formData.insert = formData.insert.filter(p => p !== id);
      //console.log('Insert ' + formData.insert);
    return;
  }

   
//    console.log(formData.insert);
  }

  const selectStatus = (id: number, checked: boolean) => {

    if(checked){
      id=0
      console.log('verdadeiro')
    }else{
      id=1
      console.log('falso')
    }
    console.log('quotaData.status')
    console.log(quotaData.status)
  }

  const checkedStatus = (id: number) => {
  if(id== 1){
    quotaData.status = 1
        console.log('é verdadeiro. id ' + id);
    return true;
   
  }
  else
  {
    quotaData.status = 0
     console.log('é falso . id ' + id);
    return false;
  }

}

const checkedIns = (id: number) => {
  if(id=== 1){
     //console.log('é verdadeiro. id ' + id);
    return true;
   
  }
  else
  {
    // console.log('é falso . id ' + id);
    return false;
  }
    
}



const selectUp = (id: number, checked: boolean) => {
  if (checked) {
    formData.update = [...formData.update, id];
     formData.notupdate = formData.notupdate.filter(p => p !== id);
    return;
  }else {
    formData.notupdate = [...formData.notupdate, id];
     formData.update = formData.update.filter(p => p !== id);
    return;
  }
}

const checkedUp = (id: number) => {
  if(id=== 1)
    return true;
  else
    return false;    
}



const selectDel = (id: number, checked: boolean) => {
  if (checked) {
    formData.delete = [...formData.delete, id];
     formData.notdelete = formData.notdelete.filter(p => p !== id);
    return;
  }else {
    formData.notdelete = [...formData.notdelete, id];
     formData.delete = formData.delete.filter(p => p !== id);
    return;
  }
}

const checkedDel = (id: number) => {
  if(id=== 1)
    return true;
  else
    return false;    
}

        //Excluir Funções do usuário
const storeUserFuncUpdate =async () => {
      await axios.put( `userfuncs/${route.params.userid}`, {funcs:excSelFuncs.value,
                                                             insert:formData.insert,
                                                             notinsert:formData.notinsert,
                                                             update:formData.update,
                                                             notupdate:formData.notupdate,
                                                             delete:formData.delete,
                                                             notdelete:formData.notdelete });
      excSelFuncs.value = [];

      getUserFuncs();
      data.msg= 'Permissões atualizadas';
      data.success = true;
      setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,1000
                );
      
    //  router.push('/sistemas');

      
    }

    const voltar = async () => {
       if (route.params.t=== 'f')
          await router.push(`/users/${route.params.t}/${route.params.id}/${0}`);
      else if (route.params.t=== 'p'){
        await router.push(`/users/${route.params.t}/${route.params.id}/${route.params.perfil}`);
      }

    }




    return {
      data,
      passwordData,
      passwordSubmit,
      selectedProfiles,
      excSelProfiles,
      userPerfils,
      profdisp ,
      submit,
      storeUserProfile,
      storeUserProfUpdate,
      userFuncs,
      funcdisp,
      funcs,
      selectedFuncs,
      excSelFuncs,
      storeUserFunc,
      storeUserFuncUpdate,
      checkedIns,
      checkedUp,
      checkedDel,
      selectIns,     
      selectUp,
      selectDel,
      voltar,
      quotaData,
      selectStatus,
      storeUserQuota,
      checkedStatus
    }
  }

}
