
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Fazer o upload de imagens direcionando o endereço da api para qual diretório deve ser enviada. É acionado pelos 
          formulários de cadastro/alteração de comunicados,links e dados do usuário em relação a imagem.
          

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import axios from 'axios';
import {SetupContext} from 'vue';

export default {
  name: "ImageUploadcp", 
  props: ['tipo'],
  emits: ['uploaded'],
  setup(props: any, context: SetupContext) {

    const upload = async (files: FileList | null) => {
      if (files === null) return;

      let rotaimg = '';
      const formData = new FormData();
      formData.append('image', files[0]);

      // console.log('Tipo');
      // console.log(props.tipo);

      if (props.tipo=='1')
      {
        rotaimg = 'uploadlink';
      }else if (props.tipo=='2'){
        rotaimg = 'uploadfoto';
      }else if (props.tipo=='3'){
        rotaimg = 'uploadcomunicado';
      }

      // const {data} = await axios.post('upload', formData);
      // separar a pasta do usuário
       const {data} = await axios.post(rotaimg, formData);

      context.emit('uploaded', data.url);
    }

    return {
      upload
    }
  }
}
