
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Vai fazer a chamado dos componentes do menu e servir como base para mostrar o conteúdo de todas os outros componentes.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {reactive,onMounted,computed, ref,watch} from "vue";
import {useStore} from "vuex";
import {useRoute,useRouter} from "vue-router";
import Comunicado from '@/components/Comunicado.vue';
import Link from '@/components/Link.vue';
import Menu from '@/components/dash/Menu.vue';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import axios from "axios";
import {User} from "@/models/user";
 
export default {
      name: "Layout",
      components: { Menu, Comunicado, Link,Loading},  
       setup() {

        const store = useStore();
        const router = useRouter();
        const route = useRoute();


        const serverimg = ref('');

      const data = reactive({
        rodape: '',
        nome_sistema: '',
        boasvinda:'',
        showcarousel:false,
        col_um: 0,
        col_dois: 0,
        col_tres: 0,
        col_quatro:0,
        facebook:0,
        instagram:0,
        logo:'',
        msg:'',
        success:false,
        error:false,
        serverimg:'', //process.env.VUE_APP_SERVER_API, //substitui a urlimg
        cats:'',
        label_rod1:'',
        label_rod2:'',
        label_rod3:'',
        label_rod4:'',
        origem:0,
        showMenu:'offcanvas offcanvas-end',
        style:'visibility: hidden;',
        arial_hidden: true,
        arial_modal:false,
        hiddenMenu:true,
        esconde:'data-bs-dismiss="offcanvas"'
      });

        const categoriaLinks = ref(<any>[]);
        const links = ref(<any>[]);

        const isLoading = ref(false);

        const showMenu = ref(true);

        const rodape1= ref(<any>[]);
        const rodape2= ref(<any>[]);
        const rodape3= ref(<any>[]);
        const rodape4= ref(<any>[]);

        //const cats= ref(<any>[]);

        const instagram= ref(<any>[]);
        const facebook= ref(<any>[]);

        const user = computed( () => store.state.user);

        const infoData = reactive ( {
            first_name: user.value.first_name,
            last_name: user.value.last_name,
            email: user.value.email,
            foto: user.value.foto
            
        });
 
     
        
        onMounted(async () => {

            if(window.location.hostname==process.env.VUE_APP_IP_EXTERNO)
            {
              data.serverimg = process.env.VUE_APP_SERVER_API_EXTERNA;
              data.origem = 1;
            }

            else 
            {
              data.serverimg = process.env.VUE_APP_SERVER_API;
              data.origem = 0;
            }
              

            try{
              const {data} = await axios.get('user');

              await store.dispatch('setUser', data);

            } catch (e){

              await router.push('/login');
            }

            // isLoading.value = true;
            // const orin_acess = await axios.get('getoriginacess');
            // isLoading.value = false;

            isLoading.value = true;
            const response = await axios.get(`showlayoutsite/1`);
            isLoading.value = false;
            data.rodape = response.data.rodape;
            data.nome_sistema= response.data.nome_sistema;
            data.boasvinda= response.data.boasvinda;
            data.showcarousel = response.data.showcarousel == 1 ? true : false;
            data.col_um= response.data.col_um;
            data.col_dois = response.data.col_dois;
            data.col_tres = response.data.col_tres;
            data.col_quatro = response.data.col_quatro;
            data.facebook = 0;//response.data.facebook;
            data.instagram = 0;// response.data.instagram;
            data.logo = response.data.logo;

            isLoading.value = true;
            const apilink = await axios.get('showalllinks');
            links.value = apilink.data;
            isLoading.value = false;

            getlinks();

        });


        const escondeMenu = (valor:any) => {

          if(!valor.hidden){

            data.hiddenMenu = true;

            data.showMenu = "offcanvas offcanvas-end";
            data.style='visibility: hidden;',
            data.arial_hidden= true,
            data.arial_modal=false

          }

        }


       const btnescondeMenu = () => {
            data.hiddenMenu = true;
            data.showMenu = "offcanvas offcanvas-end";
            data.style='visibility: hidden;';
            data.arial_hidden= true;
            data.arial_modal=false;
         // data.showMenu = valor.hidden;
        }

        

        const mostrarMenu = () => {
          data.hiddenMenu = false;
          //console.log('mostrar');
          data.showMenu = "offcanvas offcanvas-end show";
          data.style='visibility: visible;',
          data.arial_hidden= false,
          data.arial_modal=true
          
        }


        


        const getlinks= async () => {

            rodape1.value=[];
            rodape2.value=[];
            rodape3.value=[];
            rodape4.value=[];

            data.cats = data.col_um + "," + data.col_dois + "," + data.col_tres + "," + data.col_quatro 


            isLoading.value = true;
            const apicategoria = await axios.get(`getallcatlinks/${data.cats}`);
            categoriaLinks.value = apicategoria.data;
            isLoading.value = false;

           // console.log(categoriaLinks.value);

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_um)
               {
                 rodape1.value.push(v);
                 data.label_rod1 = v['categoria'];
               }
                 
            });

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_dois)
               {
                 rodape2.value.push(v);
                 data.label_rod2 = v['categoria'];
               }
                 
            });

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_tres)
               {
                 rodape3.value.push(v);
                 data.label_rod3 = v['categoria'];
               }
                 
            });

            categoriaLinks.value.forEach( (v:any, i:any) => {
              
               if (v['cat_id']==data.col_quatro)
               {
                 rodape4.value.push(v);
                 data.label_rod4 = v['categoria'];
               }
                 
            });

            //console.log(rodape1.value);


        }

        
        watch(user, () => {
            if(user.value !==null){
                infoData.first_name = user.value.first_name;
                infoData.last_name = user.value.last_name;
                infoData.email = user.value.email;
                infoData.foto = user.value.foto;
            }//else{
            //      await router.push('/');
            //  }
        });

        const logout = async () => {
            await axios.post('logout');
            await router.push('/');
            
        }

        const home = async () => {
            await router.push('/dasboard');
            
        }


        return {
            infoData,
            escondeMenu,
            mostrarMenu,
            btnescondeMenu,
            logout,
            home,
            data,
            rodape1,
            rodape2,
            rodape3,
            rodape4, 
        }
    }
    


}
