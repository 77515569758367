import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Inicio from '../views/Inicio.vue';
import Login from '@/views/Login.vue';
import Profile from '@/views/Profile.vue';

import Dash from '@/views/dash/Layout.vue';
import Users from '@/views/users/Users.vue';
import UserEdit from '@/views/users/UserEdit.vue';
import UserCreate from '@/views/users/UserCreate.vue';

import Links from '@/views/Links/LinksTable.vue';
import NewLink from '@/views/Links/LinkCreate.vue';
import LinkEdit from '@/views/Links/LinkEdit.vue';
import AreaLink from '@/views/Links/AreaLink.vue';

import Perfils from '@/views/Perfils/PerfilsTable.vue';
import PerfilCreate from '@/views/Perfils/PerfilCreate.vue';
import PerfilEdit from '@/views/Perfils/PerfilEdit.vue';

import Funcoes from '@/views/Funcoes/FuncoesTable.vue';
import FuncaoCreate from '@/views/Funcoes/FuncaoCreate.vue';
import FuncaoEdit from '@/views/Funcoes/FuncaoEdit.vue';

import Categorias from '@/views/Categorias/CategoriasTable.vue';
import CategoriaCreate from '@/views/Categorias/CategoriaCreate.vue';
import CategoriaEdit from '@/views/Categorias/CategoriaEdit.vue';

import Comunicados from '@/views/Comunicados/ComunicadosTable.vue';
import ComunicadoCreate from '@/views/Comunicados/ComunicadoCreate.vue';
import ComunicadoEdit from '@/views/Comunicados/ComunicadoEdit.vue';


import Agendas from '@/views/Tasy/Agenda/AgendaMedico.vue'; 

import Cursos from '@/views/Treinamentos/Reacao/Cursos.vue'; 
import UserAvaReacao from '@/views/Treinamentos/Reacao/UserAvaReacao.vue'; 

import ListaGeral from '@/views/Treinamentos/Geral/ListaGeral.vue'; 

import MeusTreinamentos from '@/views/Treinamentos/Geral/MeusTreinamentos.vue'; 

import TreinamentoSetor from '@/views/Treinamentos/Setor/TreinamentoSetor.vue'; 

import LayoutSite from '@/views/Parametros/LayoutSite.vue';
import AcessoNegado from '@/views/users/AccessDenied.vue';
import UnlockUserTeste from '@/views/Tasy/UnlockUser/UnlockUserTeste.vue';

import Lista from '@/views/Funil/Lista/ListasTable.vue';
import ListaCreate from '@/views/Funil/Lista/ListaCreate.vue';
import ListaEdit from '@/views/Funil/Lista/ListaEdit.vue';

import ListaGrupos from '@/views/Funil/Grupo/ListaGrupos.vue';
import GrupoEdit from '@/views/Funil/Grupo/GrupoEdit.vue';
import GrupoCreate from '@/views/Funil/Grupo/GrupoCreate.vue';

import EmailCreate from '@/views/Funil/Email/EmailCreate.vue';

//Broadcast
import Broadcast from '@/views/Funil/Campanha/Broadcast/Broadcast.vue';
import BroadcastCreate from '@/views/Funil/Campanha/Broadcast/BroadcastCreate.vue';
import BroadcastEdit from '@/views/Funil/Campanha/Broadcast/BroadcastEdit.vue';

//
import Smtp from '@/views/Smtp/SmtpsTable.vue';
import SmtpEdit from '@/views/Smtp/SmtpEdit.vue';
import SmtpCreate from '@/views/Smtp/SmtpCreate.vue';
// import ListaEdit from '@/views/Funil/Lista/ListaEdit.vue';

import PusherSite from '@/views/Funil/pusher/sites/SitesTable.vue';
import PusherSiteEdit from '@/views/Funil/pusher/sites/SiteEdit.vue';
import SiteNotification from '@/views/Funil/pusher/sites/SiteNotification.vue';
import NotificationEdit from '@/views/Funil/pusher/sites/NotificationEdit.vue';
import NotificationCreate from '@/views/Funil/pusher/sites/NotificationCreate.vue';


const routes: Array<RouteRecordRaw> = [
  {path: '/:pathMatch(.*)', component: Inicio},
  {path: '/login', component: Login},
    //{path: '/register', component: Register},
  {
    
    path:'',
    component: Inicio,
    children: [
      {path:'', component: AreaLink},

    ],
  },
  {
    path:'/sistemas', 
    component: Dash,
    children: [
      //  {path: '', redirect: '/dasboard/:t/:id/:perfil'},
      {path: '', redirect: '/dasboard'},
       {path: '/dasboard', component: AreaLink},
       {path: '/users/:t/:id/:perfil', component: Users},
       {path: '/acessonegado', component: AcessoNegado},
       {path: '/newuser/:t/:id/:perfil', component: UserCreate},
       {path: '/users/:t/:id/:perfil/:userid/edit', component: UserEdit},

       {path: '/perfils/:t/:id/:perfil', component: Perfils},
       {path: '/newperfil/:t/:id/:perfil', component: PerfilCreate},
       {path: '/perfils/:t/:id/:perfil/:perfilid/edit', component: PerfilEdit},

       {path: '/funcoes/:t/:id/:perfil', component: Funcoes},
       {path: '/newfuncao/:t/:id/:perfil', component: FuncaoCreate},
       {path: '/funcoes/:t/:id/:perfil/:funcid/edit', component: FuncaoEdit},

       {path: '/links/:t/:id/:perfil', component: Links},
       {path: '/newlink/:t/:id/:perfil', component: NewLink},
       {path: '/links/:t/:id/:perfil/:linkid/edit', component: LinkEdit},
       {path: '/profile', component: Profile},

       {path: '/categorias/:t/:id/:perfil', component: Categorias},
       {path: '/newcategoria/:t/:id/:perfil', component: CategoriaCreate},
       {path: '/categorias/:t/:id/:perfil/:catid/edit', component: CategoriaEdit},

       {path: '/comunicados/:t/:id/:perfil', component: Comunicados},
       {path: '/newcomunicado/:t/:id/:perfil', component: ComunicadoCreate},
       {path: '/comunicados/:t/:id/:perfil/:comid/edit', component: ComunicadoEdit},


        //Tasy
      //  {path: '/agendas/:t/:id/:perfil', component: Agendas},

      //Moodle

      // {path: '/cursos/:t/:id/:perfil', component: Cursos},
      // {path: '/useravareacao/:t/:id/:perfil/:avarecid/edit', component: UserAvaReacao},

      // {path: '/cursoscore/:t/:id/:perfil', component: ListaGeral},

      // {path: '/meustreinamentos', component: MeusTreinamentos},

      

      // {path: '/treinamentosetor/:t/:id/:perfil', component: TreinamentoSetor},

      {path: '/layoutsite/:t/:id/:perfil', component: LayoutSite},

      {path: '/lockusertasyteste/:t/:id/:perfil', component: UnlockUserTeste},

      {path: '/listas/:t/:id/:perfil', component: Lista},
      {path: '/newlista/:t/:id/:perfil', component: ListaCreate},
      {path: '/listas/:t/:id/:perfil/:listid/edit', component: ListaEdit},

      {path: '/lista/:t/:id/:perfil/:listid/grupos',component: ListaGrupos},
      {path: '/grupo/:t/:id/:perfil/:grupoid/edit', component: GrupoEdit},
      {path: '/newgrupo/:t/:id/:perfil', component: GrupoCreate}, 
      {path: '/newemail/:t/:id/:perfil', component: EmailCreate}, 

      //Capamanha
      {path: '/broadcast/:t/:id/:perfil', component: Broadcast},
      {path: '/broadcastcreate/:t/:id/:perfil', component: BroadcastCreate},
      {path: '/broadcastedit/:t/:id/:perfil/:listid/edit', component: BroadcastEdit},

      //Smtp
      {path: '/smtp/:t/:id/:perfil', component: Smtp},
      {path: '/smtp/:t/:id/:perfil/:smtpid/edit', component: SmtpEdit},
      {path: '/newsmtp/:t/:id/:perfil', component: SmtpCreate},

      {path: '/pushersites/:t/:id/:perfil', component: PusherSite}, // Lista todos os sites do usuário para trabalhar com notificação
      // {path: '/newlista/:t/:id/:perfil', component: ListaCreate},
      {path: '/pushergrupos/:t/:id/:perfil/:siteid/edit', component: PusherSiteEdit}, // edição de um site habilitado para notificação
      {path: '/sitenotification/:t/:id/:perfil/:siteid', component: SiteNotification}, // Lista de notificação por site

      {path: '/notificationedit/:t/:id/:perfil/:notid/:siteid/edit', component: NotificationEdit}, // edição de notificação
      {path: '/notificationcreate/:t/:id/:perfil/:siteid', component: NotificationCreate}, // Criar uma nova notificação
      
      
               
    ]
  },
 
]

console.log(process.env.BASE_URL);


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
