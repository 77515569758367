
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Edição de cadastro de comunicados

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';
import ImageUploadcp from "@/components/ImageUploadcp.vue";

export default {
  name: "ComunicadoEdit",
  components: {ImageUploadcp},
  setup() {
    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      short_name: '',
      long_name: '',
      note: '',
      url: '',
      image: '',
      iscard: false,
      iscardtop: false,
      user_action: 1,
      msg:'',
      success:false,
      error:false,
      serverimg:process.env.VUE_APP_SERVER_API   
    });

    onMounted(async () => {

     checkAcesso();

      const response = await axios.get(`comunicados/${route.params.comid}`);

      data.short_name = response.data.short_name;
      data.long_name= response.data.long_name;
      data.note= response.data.note;
      data.url= response.data.url;
      data.iscard= response.data.iscard != 0 ? data.iscard=true : data.iscard=false;
      data.iscardtop= response.data.iscardtop != 0 ? data.iscardtop=true : data.iscardtop=false;  
      data.image = response.data.image;

      console.log(data.iscard);
    });

    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }


    const checkiscard= (valor:any) =>{ 
             data.iscard= valor==false ? data.iscard=true : data.iscard=false;  
            // console.log(data.iscard); 
           }
    
    const checkiscardtop= (valor:any) =>{ 
             data.iscardtop= valor==false ? data.iscardtop=true : data.iscardtop=false;  
            // console.log(data.iscard); 
    }

    const submit = async () => {
      await axios.put(`comunicados/${route.params.comid}`, data);

        data.msg= 'Comunicado atualizado';
        data.success = true;
        setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,500
                );


    }

    return {
      data,
      checkiscard,
      checkiscardtop,
      submit
    }
  }
}
