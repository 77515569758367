
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Listar as funções cadastradas no sistema.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {Funcao} from "@/models/funcao";
import axios from "axios";

export default {
    name:"FuncoesTable",
    setup() {
       
        const funcoes = ref<Funcao[]>([]);

        const acesso = reactive({
            btnInsert:false,
            btnAlter:false,
            btnDelete:false,
            
        });
        
        const filteredTable = ref(<any>[]);
        const dados = reactive({
            totalreg:0,     
        });

        const lastPage = ref( 0);
        const perPage = ref(9);
        const page = ref(1);

        const store = useStore();
        const userlogin = computed( () => store.state.user);

        const router = useRouter();
        const route = useRoute();

        onMounted( async () => {
            loadAcessos();
            loadtable();
         //   console.log(links.value);
        });

        const loadAcessos = async () => {
          //   console.log(userlogin.value.id);
        

            if (route.params.t=== 'f'){
                const resp = await axios.get(`btngetshowuserfunc/${route.params.id}/${userlogin.value.id}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;
            }else if (route.params.t=== 'p'){

                const resp = await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`);
                acesso.btnInsert = resp.data.insert === 1 ? true : false;
                acesso.btnAlter =  resp.data.update === 1 ? true : false;
                acesso.btnDelete = resp.data.delete === 1 ? true : false;

            }else {
                acesso.btnInsert = false;
                acesso.btnAlter = false;
                acesso.btnDelete = false;
            }

        }


        const loadtable =async () => {
            const {data} = await axios.get('funcoes');
            funcoes.value = data;
            dados.totalreg = funcoes.value.length;
          //  filteredTable.value = funcoes.value;

           filteredTable.value = funcoes.value.slice(0, page.value * perPage.value);
           lastPage.value = Math.ceil(funcoes.value.length / perPage.value);
        }

        const loadMore = () => {
          page.value = page.value + 1;
          lastPage.value = Math.ceil(funcoes.value.length / perPage.value);
           filteredTable.value = funcoes.value.slice(0, page.value * perPage.value);

      }

        const search = (s: string ) => {

            filteredTable.value = funcoes.value.filter(p=> (p.short_name?p.short_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 ||
                                                      (p.long_name?p.long_name:'').toLowerCase().indexOf(s.toLowerCase()) >= 0 );
       
           
           lastPage.value = Math.ceil(filteredTable.value.length / perPage.value);
           filteredTable.value = filteredTable.value.slice(0, page.value * perPage.value);
        }





        const ativar = async (id: number) => {
                if (confirm('Deseja Ativar Função?')) {
                    await axios.put(`ativarfuncao/${id}`);
                    loadtable();
                }
        }

        const inativar = async (id: number) => {
                if (confirm('Deseja Inativar Função?')) {
                    await axios.put(`inativarfuncao/${id}`);
                    loadtable();
                }
        }

        return {
            funcoes,
            ativar,
            inativar,
            userlogin,
            acesso,
            filteredTable,
            search,
            lastPage,
            perPage,
            page,
            loadMore,
            dados,
            route           
        }
    }

}
