
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:1
Ano: 2022
Objetivo: Fazer edição do cadastro da função. A rota a ser cadastrada deve estar de acordo com o que foi
          definido no router/index.ts

*/

import {computed,reactive,onMounted, ref, SetupContext, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "GrupoEdit",
  components:  { Loading},
  props:['grupoid'],
  emits:['set-click'],
  setup(props:any, context: SetupContext) {

    const data = reactive({
      name: '',
      description: '',
      user_id:0,
      mlist_id:0,
      emailstart:1,
      grupoid:0,
      cont:0,
            
    });

    const isLoading = ref(false);
    const janela = ref(false);

    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();

    // onMounted(async () => {

          
    // });

      watch(props, async () => {
            if(props.grupoid !==null){
                  data.grupoid = parseInt(props.grupoid);
                  isLoading.value = true;
                  const response = await axios.get(`grupo/${data.grupoid}`);
                  isLoading.value = false;

                  data.name = response.data.name;
                  data.description = response.data.description;
                  data.user_id = response.data.user_id;
                  data.mlist_id = response.data.mlist_id;
                  data.emailstart = response.data.emailstart;

             }
      });


      const submit = async () => {
          isLoading.value = true;
          await axios.put(`grupo/${data.grupoid}`, data);
          isLoading.value = false;

          janela.value = true;

          context.emit('set-click', {
            hidden:true
          });
      }

    const fechar = () =>{
      janela.value = false;
    }



     const onCancel = () => console.log('Usuário cancelou o carregamento da pagina');


      return {
        data,
        submit,
        fechar,
        janela,
        isLoading,
         onCancel
      }
    }



  }



