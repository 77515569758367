
/*
Autor: Patrick Alex Maia Duarte
Ref Autor: https://patrickduarte.github.io
RDM:428
Ano: 2022
Objetivo: Editar cadastro de perfil.

*/

/**Vai fazer mudanças? Então identifique-se e descreva objetivo */

/**
 * Autor:
 * Ref Autor:
 * RDM:
 * Data:
 * Objetivo:
 */
import {computed,reactive,onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import axios from 'axios';

import Tabs from "@/components/Tab/Tabs.vue";
import Tab from "@/components/Tab/Tab.vue";


export default {
  name: "PerfilEdit",
  components:  { Tabs, Tab,},
  setup() {
    const data = reactive({
      id: '',
      short_name: '',
      long_name: '',
      note: '',
      msg:'',
      showtableFunc: false,
      success:false,
      error:false,
      achou:false,
      cont:0,
      insert:[]
      
    });
   // const roles = ref([]);

    const store = useStore();
    const user = computed( () => store.state.user);

    const router = useRouter();
    const route = useRoute();


    const funclist = ref([]);
    const funcselect= ref([]);
    const funcdata = ref([]);

    const userFuncs = ref([]); 
    const profileFuncs = ref([]); 
    
    const funcs = ref([]);
    const selectedFuncs = ref(<any>[]);
    const excSelFuncs = ref(<any>[]);
    const funcdisp = ref([]);

    const formData = reactive({
      insert: [] as number[],
      notinsert: [] as number[],
      update: [] as number[],
      notupdate: [] as number[],
      delete: [] as number[],
      notdelete: [] as number[]
    });

    
    onMounted(async () => {

      checkAcesso();


      const response = await axios.get(`perfils/${route.params.perfilid}`);

      data.id = response.data.id;
      data.short_name = response.data.short_name;
      data.long_name = response.data.long_name;
      data.note = response.data.note;


      getProfileFuncs();


    });


    const checkAcesso = async () => {
        
        //verifica se o usuário clicou no link de função ou perfil. Também consulta acesso para operação escolhida
        if (route.params.t=== 'f'){
              const {data}= await axios.get(`btngetshowuserfunc/${route.params.id}/${user.value.id}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');
        }else if (route.params.t=== 'p'){

            if (!(user.value.id > 0))
               await router.push('/acessonegado');

            const {data}= await axios.get(`btngetshowperfilfunc/${route.params.id}/${route.params.perfil}`)

              if( data.update !== 1 ? true : false )
                await router.push('/acessonegado');

        }else {
          await router.push('/acessonegado');
        }
       
    }


const submit = async () => {
      await axios.put(`perfils/${route.params.perfilid}`, data);
      data.msg= 'Perfil atualizado';
      data.success = true;
      setTimeout( () => { data.msg= '';
                          data.success=false;
                          data.error=false;     
                         }
                ,1000
                );
     // await router.push('/users');
}

const getProfileFuncs = async () => {

       let achou = false;
       funcdisp.value = [];
        try {
            const resp = await axios.get(`getprofilefuncs/${route.params.perfilid}`); 
            profileFuncs.value = resp.data;
            console.log(profileFuncs.value );
            if (resp.data.length > 0){
              data.showtableFunc=true;
            }else{
              profileFuncs.value = [];
              data.showtableFunc=false;
            }
    
            //Monta lista de perfils disponíveis
            const response = await axios.get('funcs'); 
            funcs.value = response.data;  
            funcs.value.forEach((value, index) => {
              achou = false;
              profileFuncs.value.forEach((v,ind ) => {
                if (value['id']=== v['id']){
                    achou=true;
                }
              })

              if (!achou)
              {
                funcdisp.value.push(value);
              }
            });
              
        } catch (e) {
               profileFuncs.value = [];
              data.showtableFunc=false;
        } 
    }

    //Adicionar funções para usuário
    const storeProfileFunc = async () => {

      try
      {
       // console.log("Chegou na storeUserFunc");
       // console.log(selectedFuncs.value);
        await axios.post( `profilefuncs/${route.params.perfilid}`, {funcs:selectedFuncs.value} );
        
        getProfileFuncs();
      }catch (e) {
            data.msg= 'Falha na atualização';
            data.error = true;
      }finally {
      setTimeout( () => { data.msg= ''; 
                          data.success=false;
                          data.error=false;
                        }
                ,1000
                );
      }
    }

const selectIns = (id: number, checked: boolean) => {
  if (checked) {
    formData.insert = [...formData.insert, id];
    //console.log('Insert ' + formData.insert);
     formData.notinsert = formData.notinsert.filter(p => p !== id);
        // console.log('notinsert  ' + formData.notinsert);
    return;
  }else {
    formData.notinsert = [...formData.notinsert, id];
      //console.log('notinsert ' + formData.notinsert);
     formData.insert = formData.insert.filter(p => p !== id);
      //console.log('Insert ' + formData.insert);
    return;
  }

  }

const checkedIns = (id: number) => {
  if(id=== 1){
    return true;
   
  }
  else
  {

    return false;
  }
    
}

const selectUp = (id: number, checked: boolean) => {
  if (checked) {
    formData.update = [...formData.update, id];
     formData.notupdate = formData.notupdate.filter(p => p !== id);
    return;
  }else {
    formData.notupdate = [...formData.notupdate, id];
     formData.update = formData.update.filter(p => p !== id);
    return;
  }
}

const checkedUp = (id: number) => {
  if(id=== 1)
    return true;
  else
    return false;    
}



const selectDel = (id: number, checked: boolean) => {
  if (checked) {
    formData.delete = [...formData.delete, id];
     formData.notdelete = formData.notdelete.filter(p => p !== id);
    return;
  }else {
    formData.notdelete = [...formData.notdelete, id];
     formData.delete = formData.delete.filter(p => p !== id);
    return;
  }
}

const checkedDel = (id: number) => {
  if(id=== 1)
    return true;
  else
    return false;    
}

        //Excluir Funções do Perfil
const storeProfileFuncUpdate =async () => {
   // console.log('Items selecionados para exclusão ' + excSelFuncs.value);
      await axios.put( `profilefuncs/${route.params.perfilid}`, {funcs:excSelFuncs.value,
                                                             insert:formData.insert,
                                                             notinsert:formData.notinsert,
                                                             update:formData.update,
                                                             notupdate:formData.notupdate,
                                                             delete:formData.delete,
                                                             notdelete:formData.notdelete });
      excSelFuncs.value = [];

      getProfileFuncs();
    }

    const voltar = async () => {
        if (route.params.t=== 'f')
            await router.push(`/perfils/${route.params.t}/${route.params.id}/${0}`);
        else if (route.params.t=== 'p'){
          await router.push(`/perfils/${route.params.t}/${route.params.id}/${route.params.perfil}`);
        }

    }





    return {
      data,
      submit,
      userFuncs,
      profileFuncs,
      funcdisp,
      funcs,
      selectedFuncs,
      excSelFuncs,
      storeProfileFunc,
      storeProfileFuncUpdate,
      checkedIns,
      checkedUp,
      checkedDel,
      selectIns,     
      selectUp,
      selectDel,
      voltar
    }
  }

}
